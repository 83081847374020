import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions'
import Feature from './Feature';

class FeatureContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.actions.getAll(); 
  }

  render() {
    const { data, actions: { deleteItem } } = this.props;
    return (
      <Feature
        {...this.props}
        data={data}
        deleteItem={deleteItem}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Admin.Feature,
    featureCategories: state.Admin.FeatureCategory.data
  }
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedFeature = connect(mapStateToProps, mapDispatchToProps)(FeatureContainer);

export { ConnectedFeature as Feature };

