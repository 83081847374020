import client from '../../../../client';

const apiSlug = 'accounts';

function getOne({ id }) {
  return client.get(`${apiSlug}/${id}`);
}
function getAll({ limit, offset }) {
  return client.get(`${apiSlug}?offset=${offset}&limit=${limit}`);
}


function update({ data }) {
  return client.put(`${apiSlug}/${data.id}`, data);
}

function add({ data }) {
  return client.post(apiSlug, data);
}

function del({ id }) {
  return client.delete(`${apiSlug}/${id}`);
}


export {
  getAll, update, add, del, getOne,
};
