import React from 'react';

import Hero from "./Hero";
import { MyCoverForm } from './MyCoverForm';
import {Providers, Reviews} from '../common';

const MyCover = (props) => {
  return (
    <main>
      <Hero />
      <MyCoverForm {...props} />
      <Providers />
      <Reviews />
    </main>
  );
}

export default MyCover;
