import React, { useEffect, useState } from 'react';
import {
  Form, 
  FormGroup, 
  Label, 
  InputGroup, 
  InputGroupAddon, 
  InputGroupText, 
  Button,
  Row,
  Col,
  PopoverBody, UncontrolledPopover
} from 'reactstrap';
import * as yup from 'yup';
import * as Datetime from 'react-datetime';
import moment from 'moment';
import useForm from 'react-hook-form';
import { withRouter } from 'react-router-dom';

import { StyledDropdown } from '../../common';

const typesOption = [
  { value: 'Single', label: 'Single' },
  { value: 'Couple', label: 'Couple' },
  { value: 'Family', label: 'Family' },
];

const childrenOption = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
];

const schema = yup.object().shape({
  clientType: yup.string().required(),
  children: yup.string().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
});

const OSHCForm = (props) => {
  const { isQuotePage, application, match: { params: { coverType } } } = props;
  const [formData, setFormData] = useState(application);

  const {
    register, handleSubmit, setValue, errors,
  } = useForm({
    validationSchema: schema,
    defaultValues: application,
  });

  useEffect(() => {
    setFormData(application);
  }, [application]);


  const onSubmit = (data) => {
    const { updateApplication, history } = props;
    const startDate = moment(data.startDate).format('YYYY-MM-DD');
    const endDate = moment(data.endDate).format('YYYY-MM-DD');

    updateApplication({
      application: {
        clientType: data.clientType,
        children: data.children,
        startDate,
        endDate,
        coverType,
      },
    });
    history.push(`/cover-type/oshc/quote/?clientType=${data.clientType}&children=${data.children}&startDate=${startDate}&endDate=${endDate}`);
  };

  const handleChange = (selectedOption, name) => {
    setValue([name], selectedOption.value);
    const changeData = { [name]: selectedOption.value };
    if (name === 'clientType') {
      if (selectedOption.value === 'Single' || selectedOption.value === 'Couple') {
        changeData.children = '0';
      } else {
        changeData.children = '1';
      }
    }
    setValue('children', changeData.children);
    setFormData({ ...formData, ...changeData });
  };

  const handleDateChange = (selectedDate, name) => {
    setFormData({ ...formData, [name]: selectedDate });
    setValue([name], selectedDate);
  };

  const validStartDate = (current) => {
    const yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  };

  const validEndDate = (current) => {
    const oneMonthAfterStartDate = moment(formData.startDate).add(1, 'months').subtract(2, 'day');
    return current.isAfter(oneMonthAfterStartDate);
  };

  useEffect(() => {
    register({ name: 'clientType' });
    register({ name: 'children' });
    register({ name: 'startDate' });
    register({ name: 'endDate' });
  }, [register]);

  return (
    <>
    <Row>
        <Col lg="12" className="text-right">
            <span className="form-info"> <span className="form-error">*</span> Indicates required field</span>
        </Col>
      </Row>
    <Form onSubmit={handleSubmit(onSubmit)} className={isQuotePage && 'quote-form'}>
      
      <FormGroup className="form-item">
        <Label>Type<span className="form-error">*</span></Label>
        <InputGroup className="input-group-alternative">

          <StyledDropdown
            selected={typesOption.find((item) => item.value === formData.clientType)}
            onChange={(selected) => handleChange(selected, 'clientType')}
            name="clientType"
            options={typesOption}
            placeholder="Select type"
          />
        </InputGroup>

      </FormGroup>

      <FormGroup className="form-item">
        <Label>Children</Label>
        <InputGroup className="input-group-alternative disable">
          <StyledDropdown
            selected={childrenOption.find((item) => item.value === formData.children)}
            name="children"
            isDisabled={formData.clientType === 'Single' || formData.clientType === 'Couple'}
            onChange={(selected) => handleChange(selected, 'children')}
            options={childrenOption}
            placeholder="Select childrens"
          />
        </InputGroup>
      </FormGroup>

      <FormGroup className="form-item">
        <Label>Policy start date<span className="form-error mr-1">*</span> 
        <i id="tooltip_policy_start_date" className="fa fa-question-circle"></i> 
        <UncontrolledPopover placement="top" target="tooltip_policy_start_date">
          <PopoverBody>
           Policy start date should be the day you will arrive in Australia. In case you are not sure choose your visa start date.
          </PopoverBody>
        </UncontrolledPopover> </Label>
        <InputGroup className="input-group-alternative">
          <Datetime
            inputProps={{
              placeholder: 'Pick start date',
            }}
            className="date-time-input"
            timeFormat={false}
            isValidDate={validStartDate}
            closeOnSelect
            name="startDate"
            value={moment(formData.startDate)}
            dateFormat="YYYY-MM-DD"
            onChange={(selectedDate) => handleDateChange(selectedDate, 'startDate')}
          />

          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-calendar-grid-58" />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        {errors.startDate && (<small className="form-error">This field is required</small>)}
      </FormGroup>

      <FormGroup className="form-item">
        <Label>Policy end date<span className="form-error mr-1">*</span>
          <i id="tooltip_policy_end_date" className="fa fa-question-circle"></i> 
          <UncontrolledPopover placement="top" target="tooltip_policy_end_date">
            <PopoverBody>
            Policy end date should be your visa end date.
            </PopoverBody>
          </UncontrolledPopover>
        </Label>
        <InputGroup className="input-group-alternative">
          <Datetime
            inputProps={{
              placeholder: 'Pick end date',
            }}
            className="date-time-input"
            timeFormat={false}
            isValidDate={validEndDate}
            closeOnSelect
            name="endDate"
            value={moment(formData.endDate)}
            dateFormat="YYYY-MM-DD"
            onChange={(selectedDate) => handleDateChange(selectedDate, 'endDate')}
          />
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-calendar-grid-58" />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        {errors.endDate && (<small className="form-error">This field is required</small>)}
      </FormGroup>

      <div className="form-action">
        <Button
          block
          className="bg-gradient-primary"
          color="primary"
          size="lg"
          type="submit"
        >
                Get quote now
        </Button>
      </div>

    </Form>
  </>
  );
};

export default withRouter(OSHCForm);
