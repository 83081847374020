import React from 'react';

import {Providers, Reviews} from '../common';

import Features from './Features';
import Hero from './Hero';

const AboutUs = (props) => {
  return (
    <main>
      <Hero />
      <Features />
      <Providers />
      <Reviews />
    </main>
  );
}

export default AboutUs;
