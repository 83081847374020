import React, { useEffect } from 'react';
import {
  UncontrolledCollapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Headroom from 'headroom.js';
import { Link } from 'react-router-dom';

const Header = (props) => {
  const applicationTitle = 'OSHC Compare';

  useEffect(() => {
    const headroom = new Headroom(document.getElementById('navbar-main'));
    // initialise
    headroom.init();
  });
  return (
    <header className="header-global">
      <Navbar
        className="navbar-main navbar-transparent navbar-light headroom"
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <NavbarBrand className="mr-lg-3" href="/">
            <img
              alt={applicationTitle}
              src={require('../../../../assets/images/brand/logo-white.png')}
            />
            {
                      props.currentAccount && (
                        <>
                          <div style={{position: 'absolute', fontSize: 10}}>{props.currentAccount.name}</div>
                        </>
                      )
                    }
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar_global">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar_global" style={{justifyContent: 'flex-end'}}>
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <NavLink href="/">
                    <img
                      alt={applicationTitle}
                      src={require('../../../../assets/images/brand/logo.png')}
                    />
                    {
                          props.currentAccount && (
                            <>
                              <div style={{position: 'absolute', fontSize: 10}}>{props.currentAccount.name}</div>
                            </>
                          )
                        }
                  </NavLink>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar_global">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="navbar-nav-hover align-items-lg-center" navbar>

              <Link
                className="nav-link"
                to="/"
              >
                <strong>HOME</strong>
              </Link>

              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <i className="ni ni-ui-04 d-lg-none mr-1" />
                  <span className="nav-link-inner--text"><strong>COMPARE</strong></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-xl">
                  <div className="dropdown-menu-inner">
                    <Media
                      className="d-flex align-items-center"
                      href="/cover-type/oshc"
                    >
                      <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                        <i className="fa fa-graduation-cap" />
                      </div>
                      <Media body className="ml-3" >
                        <h6 className="heading text-primary mb-md-1">
                                OSHC
                        </h6>
                        <p className="description d-none d-md-inline-block mb-0">
                                    Overseas Student Health Cover
                        </p>
                      </Media>
                    </Media>
                    {/* <Media 
                      className="d-flex align-items-center"
                      href="/cover-type/ovhc"
                    >
                      <div className="icon icon-shape bg-gradient-secondary rounded-circle text-white">
                        <i className="fa fa-briefcase" />
                      </div>
                      <Media body className="ml-3">
                        <h6 className="heading text-primary mb-md-1">
                                    OVHC
                        </h6>
                        <p className="description d-none d-md-inline-block mb-0">
                                    Overseas Visitors Health Cover
                        </p>
                      </Media>
                    </Media> */}
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <Link
                  className="nav-link"
                  to="/my-cover"
                >
                  <strong>MY COVER</strong>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                    className="nav-link"
                    to="/about-us"
                  >
                    <strong>ABOUT US</strong>
                  </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  to="/faqs"
                >
                  <strong>FAQS</strong>
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link"
                  to="/reviews"
                >
                  <strong>REVIEWS</strong>
                </Link>
              </NavItem>
              <NavItem>
                <AnchorLink className="nav-link" href="#contact-form"><strong>CONTACT</strong></AnchorLink>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
