import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../actions'
import ApplicationFormOVHC from './ApplicationFormOVHC';

class ApplicationFormContainerOVHC extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updatingApplication && !this.props.updatingApplication && !this.props.applicationError) {
      const { application } = this.props;
      window.location.href = application.HealthCover.agentUrl
    }
  }




  render() {
    const { application, currentAccount,  actions: {updateApplication} } = this.props;
    
    return (
      <ApplicationFormOVHC
        {...this.props}
        application={{...application, coverType: 'ovhc', accountId: currentAccount && currentAccount.id}}
        updateApplication={updateApplication}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Front.Application,
    currentAccount: state.Front.CurrentAccount.data
  }
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedApplicationFormOVHC = connect(mapStateToProps, mapDispatchToProps)(ApplicationFormContainerOVHC);

export { ConnectedApplicationFormOVHC as ApplicationFormOVHC };

