import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Table,
    Badge,
    TabPane,
  } from "reactstrap";
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Avatar from 'react-avatar';
import { FilePond  } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import  config from '../../../../../config';

import Header from "../../../Headers/SlimHeader";
import DependentInfo from './DependentInfo';
import ApplicationTabs from './ApplicationTabs';
import ApplicationActivity from './ApplicationActivity';
import { ApplicationComment } from './ApplicationComment';

const ApplicationDetails = (props) => {
  
    const { currentItem, history, authUser, getCurrent, deletingCertificate, activities, deleteCertificate  } = props;
    
    const removeCertificate = (event) => {
      event.preventDefault();
      
      deleteCertificate({id: currentItem.id})
    }

    if(!currentItem) return null;

    return (
        <>
            <Header />
            <Container fluid>
            <Row className="align-items-center" style={{marginTop: -34}}>
                    <Col xs="12">
                      <ApplicationTabs>
                        <TabPane tabId="1">

                        <Row>
                            <Col className="order-xl-1" lg="12" xl="9">
                              <Card className="bg-white shadow">
                                <CardHeader className="bg-white border-0">
                                  
                                  <Row className="align-items-center">
                                    <Col xs="8">
                                      
                                        <h3 className="mb-0">
                                          Application details &nbsp;
                                          {currentItem.status === 'inProgress' && (<Badge color="info">In progress</Badge>)}
                                          {currentItem.status === 'paymentMade' && (<Badge color="danger">Payment made</Badge>)}
                                          {currentItem.status === 'certificateIssued' && (<Badge color="success">Certificate issued</Badge>)}  
                                        </h3> <br />
                                        {currentItem.Account ? `Account: ${currentItem.Account.name}` : ''}
                                    </Col>
                                  
                                    <Col className="text-right" xs="4">
                                      <Button
                                        color="grey"
                                        onClick={history.goBack}
                                        size="sm"
                                      >
                                        Back
                                      </Button>
                                    </Col>
                                  </Row>
                                </CardHeader>
                                <CardBody>
                                      <Row>
                                        <Col lg="8">
                                        <h6 className="heading-small text-muted mb-4">
                                          Personal information
                                        </h6>
                                        <Card className="mb-4">
                                          <Table className="align-items-center table-flush" responsive>
                                            <tbody>
                                              <tr>
                                                <th width="200">Title</th>
                                                <td>{currentItem.title}</td>
                                              </tr>
                                              <tr>
                                                <th>First name</th>
                                                <td>{currentItem.firstName}</td>
                                              </tr>
                                              <tr>
                                                <th>Last name</th>
                                                <td>{currentItem.lastName}</td>
                                              </tr>
                                              <tr>
                                                <th>Gender</th>
                                                <td>{currentItem.gender}</td>
                                              </tr>
                                              <tr>
                                                <th>Date of birth</th>
                                                <td>{moment(currentItem.dateOfBirth).format('YYYY-MM-DD')}</td>
                                              </tr>
                                              <tr>
                                                <th>Email address</th>
                                                <td>{currentItem.email}</td>
                                              </tr>
                                              <tr>
                                                <th>Mobile</th>
                                                <td>{currentItem.mobile}</td>
                                              </tr>
                                              <tr>
                                                <th>Country</th>
                                                <td>{currentItem.country}</td>
                                              </tr>
                                              <tr>
                                                <th>Passport number</th>
                                                <td>{currentItem.passportNumber}</td>
                                              </tr>
                                              <tr>
                                                <th>Visa type</th>
                                                <td>{currentItem.visaType}</td>
                                              </tr>
                                              <tr>
                                                <th>School, college or university name</th>
                                                <td>{currentItem.college || '-'}</td>
                                              </tr>
                                              <tr>
                                                <th>Student number</th>
                                                <td>{currentItem.studentNumber || '-'}</td>
                                              </tr>
                                              <tr>
                                                <th>Australian address</th>
                                                <td>{currentItem.australianAddress || '-'}</td>
                                              </tr>
                                              <tr>
                                                <th>Current health provider name</th>
                                                <td>{currentItem.currentHealthProviderName || '-'}</td>
                                              </tr>
                                              <tr>
                                                <th>Current health provider number</th>
                                                <td>{currentItem.currentHealthProviderNumber || '-'}</td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </Card>

                                        {
                                          (currentItem.clientType === 'Couple' || currentItem.clientType === 'Family') && (
                                            <>
                                            <hr />
                                              <h6 className="heading-small text-muted mb-4">
                                                Partner information
                                              </h6>
                                              <Card className="mb-4">
                                                <DependentInfo {...currentItem.ApplicationPartner} />
                                              </Card>
                                            </>
                                          )
                                        }
                                        {
                                          (currentItem.clientType === 'Family' && currentItem.ApplicationChildren.length) ? (
                                            <>
                                          
                                              <h6 className="heading-small text-muted mb-4">
                                                Children information
                                              </h6>
                                              {
                                                currentItem.ApplicationChildren.map(children => (
                                                  <Card className="mb-4" key={children.id}><DependentInfo {...children} /></Card>
                                                ))
                                              }
                                            </>
                                          ) : null
                                        }
                                        </Col>
                                        <Col lg="4">
                                            <h6 className="heading-small text-muted mb-4">
                                              Cover details
                                            </h6>
                                            <div className="application-sidebar">
                                                <div className='sidebar-provider-logo'>
                                                    <img src={currentItem && currentItem.HealthCover.Provider.logo} alt={currentItem && currentItem.HealthCover.name} />
                                                </div>
                                                <h2><strong>${currentItem.premium}</strong></h2>
                                                <h3>{currentItem && currentItem.HealthCover.name}</h3>
                                                <div>
                                                    <p>
                                                        Type: <span>{currentItem.clientType}</span> <br />
                                                        Childrens: <span>{currentItem.children}</span> <br />
                                                        Cover start date: <span>{moment(currentItem.startDate).format('YYYY-MM-DD')}</span> <br />
                                                        Cover end date: <span>{moment(currentItem.endDate).format('YYYY-MM-DD')}</span>
                                                    </p>
                                                </div>
                                            </div>

                                            {
                                                currentItem.status !== 'inProgress'  && currentItem.ApplicationPayment && (
                                                  <>
                                                    <h5>Payment details</h5>
                                                    <div className="application-sidebar">
                                                      {
                                                        currentItem.ApplicationPayment.paymentType === 'Poli' && (
                                                          <div className='sidebar-provider-logo'>
                                                              <img src="https://resources.apac.paywithpoli.com/poli-logo-43.png" alt="Poli" />
                                                          </div>
                                                        )
                                                      }

{
                                                        currentItem.ApplicationPayment.paymentType === 'stripe' && (
                                                          <div className='sidebar-provider-logo'>
                                                              <a href="https://stripe.com" rel="noopener noreferrer" target="_blank">
                                                                  <img alt="Powered by stripe" src="https://insurance-compare.s3-ap-southeast-2.amazonaws.com/powered_by_stripe.png" />
                                                              </a> 
                                                          </div>
                                                        )
                                                      }
                                                      
                                                      <div>
                                                        <p>
                                                Transaction ID: <span>{currentItem.ApplicationPayment.transactionId}</span> <br />
                                                Description: <span>{currentItem.ApplicationPayment.description}</span> <br />
                                                Date: <span>{currentItem.ApplicationPayment.createdAt}</span> <br />
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </>
                                                )
                                              }

                                            <div>
                                              {
                                                currentItem.User && (
                                                  <>
                                                    <h5>Assignee</h5>
                                                    <div className="application-sidebar">
                                                      <div style={{display: 'flex'}}>
                                                          <Avatar name={`${currentItem.User.firstName} ${currentItem.User.firstName}`} size="50" round={true} />
                                                          <div className="ml-2">
                                                          <strong>{currentItem.User.firstName}  {currentItem.User.firstName} </strong><br />
                                                          <small>{currentItem.User.email}</small>
                                                          </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                )
                                              }
                                            

                                              {
                                                !currentItem.certificate && (currentItem.status === 'paymentMade') && (authUser.role === 'staff' || authUser.role === 'superadmin') && (currentItem.User && currentItem.User.id === authUser.id) && (
                                                  <>
                                                    <h5>Upload certificate</h5>
                                                    <FilePond
                                                      allowMultiple={false}
                                                      server= {
                                                        {
                                                          url: `${config.serverUrl}/api/applications/${currentItem.id}/upload`,
                                                          process: {
                                                              headers: {
                                                                  'x-access-token': localStorage.getItem('token')
                                                              },
                                                          }
                                                        }
                                                      }
                                                      accept="image/png, image/jpeg, image/gif, application/pdf"
                                                      onprocessfile={() => getCurrent({id: currentItem.id})}
                                                      />
                                                  </>
                                                )
                                              }

                                              

                                             {
                                                currentItem.certificate && (currentItem.status === 'certificateIssued') && (
                                                  <>
                                                    <h5>Certificate</h5>
                                                    <Card style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} className="p-sm">
                                                      <a href={currentItem.certificate} target="_blank" rel="noopener noreferrer"><i className="fa fa-download" style={{marginRight: 10}} /> Download certificate</a>
                                                    </Card>
                                                    {
                                                      (authUser.role === 'staff' || authUser.role === 'superadmin') && (currentItem.User && currentItem.User.id === authUser.id) &&  (
                                                        <>
                                                          {
                                                            deletingCertificate ? (<p>...</p>) : (
                                                              <Button onClick={removeCertificate} className="mt-4" size="sm">Remove certificate</Button>
                                                            )
                                                          }
                                                        </>
                                                      )
                                                    }
                                                  </>
                                                )
                                              }
                                              
                                            </div>

                                        </Col>
                                      </Row>

                                    
                                    </CardBody>
                              </Card>
                            </Col>
                          </Row>


                        </TabPane>
                        <TabPane tabId="2">
                              <ApplicationActivity history={history} activities={activities} />
                        </TabPane>
                        <TabPane tabId="3">
                              <ApplicationComment {...props}   />
                        </TabPane>
                      </ApplicationTabs>
                    </Col>
                  </Row>
          
        </Container>
        </>
    );
}

export default withRouter(ApplicationDetails);