import React from 'react';
import {  Badge,
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col 
} from "reactstrap";
import { Loader } from '../../../Common';

const VisaTypes = (props) => {
  const { data, loadingAll } = props;

  return (
    <section className="section section-lg mt--200">
    <Container>
     {
        loadingAll && (
          <Row className="text-center">
              <Col lg="12">
                <Loader color="#ffffff" />
              </Col>
          </Row>
        )
      }
      <Row className="justify-content-center">
        <Col lg="12" className="offset-lg-12">
        
          <Row className="row-grid d-flex justify-content-center">
            
            {
              data && data.filter(item => item.isActive).map((healthCoverType, index) => (
                <Col lg="5" key={healthCoverType.id}>
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5">
                        <div className={index % 2 === 1 ? "icon icon-shape bg-gradient-secondary rounded-circle text-white" : "icon icon-shape bg-gradient-primary rounded-circle text-white"}>
                          {
                            healthCoverType.slug === "oshc" && (<i className="fa fa-graduation-cap" />)
                          }
                          {
                            healthCoverType.slug === "ovhc" && (<i className="fa fa fa-briefcase" />)
                          } 
                        </div>
                      <h5 style={{fontSize: '1.23rem'}} className={index % 2 === 1 ? "text-secondary" : "text-primary"}>
                        <span className="text-uppercase">{healthCoverType.name}</span> ({healthCoverType.fullName})
                      </h5>
                      <p className="description mt-3">
                        {healthCoverType.intro}
                      </p>
                      <div className="mb-5">
                        {
                          healthCoverType.supportedVisas && JSON.parse(healthCoverType.supportedVisas).map((supportedVisa, i) => (
                            <Badge key={i} color={index % 2 === 1 ? "secondary" : "primary"} pill className="mr-1">
                            {supportedVisa}
                          </Badge>
                          ))
                        }
                        
                      </div>
                      <div className="text-center">
                        <Button
                            className={index % 2 === 1 ? "mt-4 bg-gradient-secondary" : "mt-4 bg-gradient-primary"}
                            color={index % 2 === 1 ? "secondary" : "primary"}
                            href={`/cover-type/${healthCoverType.slug}`}
                            style={{borderRadius: 30}}
                        >
                            COMPARE {healthCoverType.name} PROVIDERS
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
            }
            
          </Row>
        </Col>
      </Row>
    </Container>
  </section>
  );
}

export default VisaTypes;

