import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
  } from "reactstrap";
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import { FilePond, registerPlugin  } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import config from '../../../../../config';

import Header from "../../../Headers/SlimHeader";

registerPlugin(FilePondPluginImagePreview);

const ProviderForm = (props) => {
    const { register, handleSubmit, setValue, errors } = useForm();
    const { currentProvider, submittingForm, history } = props;
    const [formData, setFormData] = useState(currentProvider);


    useEffect(() => {
      setFormData(currentProvider);
    }, [currentProvider, setFormData]);

    const onSubmit = async ({name, intro, logo}) => {
      const { submitProviderForm } = props;
      const updatedProvider = {name, intro, logo};
      submitProviderForm({provider: {...currentProvider, ...updatedProvider}});
    }


    const updateInput = (event) => {
      setFormData({...formData, [event.target.name]: event.target.value})
    }

    const assignImage = (file) => {
      setFormData({...formData, logo: file});
      setValue('logo', file);
    }

    const {name, intro, logo, id} = formData;
    
    return (
        <>
            <Header />
            <Container fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-white shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                        <h3 className="mb-0">{id ? 'Edit provider' : 'Add new provider'}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="grey"
                        onClick={history.goBack}
                        size="sm"
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup className={errors.name && "has-danger" }>
                            <label
                              className="form-control-label"
                            >
                              Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="name"
                              type="text"
                              innerRef={register({required: true})}
                              value={name}
                              onChange={updateInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup className={errors.intro && "has-danger" }>
                            <label
                              className="form-control-label"
                            >
                              Intro
                            </label>
                            <Input
                                className="form-control-alternative"
                                rows="4"
                                type="textarea"
                                name="intro"
                                innerRef={register({required: true})}
                                value={intro}
                                onChange={updateInput}
                                />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup className={errors.logo && "has-danger" }>
                            <label
                              className="form-control-label"
                            >
                              Logo
                            </label>
                            <FilePond
                              allowMultiple={true}
                              server= {`${config.serverUrl}/api/images`}
                              accept="image/png, image/jpeg, image/gif"
                              onprocessfile={(error, file) => assignImage(file.serverId)}
                              />
                            
                            <Input
                                className="form-control-alternative"
                                rows="4"
                                type="hidden"
                                name="logo"
                                innerRef={register({required: true})}
                                value={logo}
                                />


                             <img alt="" src={logo} width={50} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                          <Col lg="12">
                          <hr className="my-4" />
                            <div className="text-right">
                                <Button
                                    color="grey"
                                    type="button"
                                    onClick={history.goBack}
                                    >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={submittingForm? true : false}
                                    >
                                    {id ? 'UPDATE' : "ADD"}
                                </Button>
                               
                            </div>
                          </Col>
                      </Row>
                    </div>
                   
                    </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        </>
    );
}

export default withRouter(ProviderForm);