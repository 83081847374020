import React, { useEffect } from 'react';
import * as yup from 'yup';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';

import Header from '../../../Headers/SlimHeader';

const formSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required').email('Invalid email address'),
  address: yup.string().required('Address is required'),
});


const FeatureForm = (props) => {
  const {
    currentItem, submittingForm, history,
  } = props;

  const {
    register, handleSubmit, errors, setValue, reset,
  } = useForm({
    validationSchema: formSchema,
    defaultValues: currentItem,
  });


  useEffect(() => {
    reset(currentItem);
  }, [currentItem, reset]);

  const onSubmit = async (updatedItem) => {
    const { submit } = props;
    submit({ data: { ...currentItem, ...updatedItem } });
  };

  const handleInputChange = (event) => {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    setValue([name], value, true);
  };

  return (
    <>
      <Header />
      <Container fluid>
        <Row>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-white shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{currentItem.id ? 'Edit account' : 'Add new account'}</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="grey"
                      onClick={history.goBack}
                      size="sm"
                    >
                        Back
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="pl-lg-4">

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                                Name
                          </label>

                          <Input
                            className="form-control-alternative"
                            innerRef={register}
                            type="text"
                            name="name"
                            onChange={handleInputChange}
                          />
                          {errors.name && <span className="formError">{errors.name.message}</span>}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                                Email
                          </label>

                          <Input
                            className="form-control-alternative"
                            type="text"
                            name="email"
                            innerRef={register}
                            onChange={handleInputChange}
                          />
                          {errors.email && <span className="formError">{errors.email.message}</span>}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                                Address
                          </label>

                          <Input
                            className="form-control-alternative"
                            type="text"
                            name="address"
                            innerRef={register}
                            onChange={handleInputChange}
                          />
                          {errors.address && <span className="formError">{errors.address.message}</span>}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="12">
                        <hr className="my-4" />
                        <div className="text-right">
                          <Button
                            color="grey"
                            type="button"
                            onClick={history.goBack}
                          >
                                    Cancel
                          </Button>
                          <Button
                            color="primary"
                            type="submit"
                            disabled={!!submittingForm}
                          >
                            {currentItem.id ? 'UPDATE' : 'ADD'}
                          </Button>

                        </div>
                      </Col>
                    </Row>
                  </div>

                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(FeatureForm);
