import * as c from './constants';
import * as applicationService from './services';
import * as leadService from './leadServices';

const updateApplicationStart = () => ({
  type: c.UPDATE_APPLICATION_START
})

const updateApplicationSuccess = ({application}) => ({
  type: c.UPDATE_APPLICATION_SUCCESS,
  application,
})

const updateApplicationFail = ({error}) => ({
  type: c.UPDATE_APPLICATION_FAIL,
  error,
})

export const updateApplication = ({application}) => async (dispatch) => {
  dispatch(updateApplicationStart())
  try {
      let updatedApplication = application;
     
      if(application.coverType === 'oshc' && application.chk1) {
        const response = application.id ? await applicationService.updateApplication({application}) : await applicationService.addApplication({application})
        updatedApplication = response.data.data
        
        dispatch(updateApplicationSuccess({ application: {...application, ...updatedApplication}  }));
      }
      else if(application.coverType === 'ovhc' && application.title) {
        await leadService.addLead({lead: application});
        dispatch(updateApplicationSuccess({ application: updatedApplication  }));
      }
      else {
        dispatch(updateApplicationSuccess({ application  }));
      }
  } catch (error) {
    dispatch(updateApplicationFail({error}))
  }
}



const getCurrentStart = () => ({
  type: c.GET_CURRENT_APPLICATION_START
})

const getCurrentSuccess = ({currentItem}) => ({
  type: c.GET_CURRENT_APPLICATION_SUCCESS,
  currentItem,
})

const getCurrentFail = ({error}) => ({
  type: c.GET_CURRENT_APPLICATION_FAIL,
  error,
})

export const getApplication = ({id}) => async (dispatch) => {
  dispatch(getCurrentStart())
  try {
      
      const { data } = await applicationService.getApplication({id});
     
      dispatch(getCurrentSuccess({currentItem: data.data}));
  } catch (error) {
    dispatch(getCurrentFail({error}))
  }
}

const clearCurrentApplicationSuccess = () => ({
  type: c.CLEAR_CURRENT_APPLICATION
})
export const clearCurrentApplication = () => async (dispatch) => {
  dispatch(clearCurrentApplicationSuccess());
}



const downloadInvoiceStart = () => ({
  type: c.DOWNLOAD_APPLICATION_INVOICE_START
})

const downloadInvoiceSuccess = () => ({
  type: c.DOWNLOAD_APPLICATION_INVOICE_SUCCESS,
})

const downloadInvoiceFail = ({error}) => ({
  type: c.DOWNLOAD_APPLICATION_INVOICE_FAIL,
  error,
})

export const downloadInvoice = ({id}) => async (dispatch) => {
  dispatch(downloadInvoiceStart())
  try {
      const { data } =  await applicationService.downloadInvoice({id});
      window.location.href = data.url; 
      dispatch(downloadInvoiceSuccess());
  } catch (error) {
    dispatch(downloadInvoiceFail({error}))
  }
}