import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Form
} from "reactstrap";
import useForm from 'react-hook-form';

import { CountriesDropdown } from '../../index';


const Contact = (props) => {
  const { message, submitMessage, formSuccess } = props;
  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: message
  });
  
  const onSubmit = handleSubmit(message => {
    const { currentAccount } = props;
    
    submitMessage({message: {...message, accountId: currentAccount && currentAccount.id}})
  });

  const handleChange = (selectedOption) => {
    setValue("country", selectedOption.value);
  };

  useEffect(() => {
    register({ name: "country" });
  }, [register]);

  return (
    <>
      <section className="section section-lg bg-primary contact-banner"  id="contact-form">
            <Container className="pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Get in touch</h2>
                  <p className="lead text-white">
                  If you have any queries or want any further information and details, please do get in touch. We would love to hear from you.
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-building text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Office address</h5>
                  <p className="text-white mt-3">
                    Level 6 263 Clarence Street Sydney NSW 2000 Australia
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-email-83 text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Email</h5>
                  <p className="text-white mt-3">
                    <a className="text-white" href="mailto:support@oshccompare.com.au">support@oshccompare.com.au</a>
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="fa fa-phone text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Call us at</h5>
                  <p className="text-white mt-3">
                      +61 2 9269 0070
                  </p>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="shadow">
                    {
                      formSuccess && (
                        <CardBody className="p-lg-5">
                            <h4 className="mb-1">Thank you for your message</h4>
                            <p className="mt-0">
                            We will be back to you soon.
                            </p>
                        </CardBody>
                      )
                    }

                    {
                      !formSuccess && (
                        <CardBody className="p-lg-5">
                            <h4 className="mb-1">Aspiring for the best and affordable Health Coverage?</h4>
                            <p className="mt-0">
                              Get in touch; we will be with you within 24 hrs.
                            </p>
                            <Form onSubmit={onSubmit}>
                            <FormGroup className={errors.fullName && "has-danger"}>
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-user-run" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Full name"
                                  type="text"
                                  name="fullName"
                                  innerRef={register({required: true, max: 80})}
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup  className={errors.email && "has-danger"}>
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-email-83" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Email address"
                                  type="email"
                                  name="email"
                                  innerRef={register({required: true})}
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup className={errors.country && "has-danger"}>
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-square-pin" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                
                                <CountriesDropdown defaultSelectedCountry={message.country}  onChange={handleChange}  innerRef={() => register({required: true})}  name="country"  placeholder="Country of residence"  />
                              </InputGroup>
                              
                            </FormGroup>
                            <FormGroup  className={errors.mobile && "has-danger"}>
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="ni ni-mobile-button" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Mobile number"
                                  type="text"
                                  name="mobile"
                                  innerRef={register({required: true, pattern: /[0-9]/})}
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup className={errors.mobileNumber ?  "mb-4 has-danger": "mb-4"}>
                              <Input
                                className="form-control-alternative"
                                cols="80"
                                name="message"
                                placeholder="Type your message..."
                                rows="4"
                                type="textarea"
                                innerRef={register({required: true})}
                              />
                            </FormGroup>
                            <div>
                              <Button
                                block
                                className="bg-gradient-primary"
                                color="primary"
                                size="lg"
                                type="submit"
                              >
                                Send Message
                              </Button>
                            </div>
                            </Form>
                          </CardBody>
                      )
                    }
                    
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
    </>
  );
}

export default Contact;
