import React from 'react';
import {  Badge,
    Container,
    Row,
    Col 
} from "reactstrap";

const Features  = ({coverType}) => {
  let banner = require("../../../../assets/images/theme/oshc_banner@2x.png");
  if(coverType === 'ovhc') {
    banner = require("../../../../assets/images/theme/ovhc_banner@2x.png");
  }

  return (
    <section className="section section-lg">
    <Container>
      <Row className="row-grid align-items-center">
        <Col className="order-md-2" md="6">
          <img
            alt="..."
            className="img-fluid floating"
            src={banner}
          />
        </Col>
        <Col className="order-md-1" md="6">
          <div className="pr-md-5">
            {
              coverType === 'oshc' ? (
                <>
                  <h3>Government approved OSHC providers</h3>
                  <p>
                    All OSHC providers we compare are Australian government approved. Health cover from all providers meet your overseas student visa requirements. 
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <p className="mb-0">
                              Meet student visa health insurance requirement
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <p className="mb-0">Provides medical treatment</p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <p className="mb-0">
                              Provides emergency cover
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <p className="mb-0">
                              Covers in-hospital medical services
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <p className="mb-0">
                              Covers partial out of hospital medical services
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <p className="mb-0">
                              All providers provide refunt
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                </>
              ) : (
                <>
                <h3>Government approved OVHC providers</h3>
                <p>
                    All OVHC providers we compare are Australian government approved. Health cover from all providers meet your overseas visitor visa requirements. 
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <p className="mb-0">
                              Meet visitor visa requirement
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <p className="mb-0">Provides medical treatment</p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <p className="mb-0">
                              Provides emergency cover
                            </p>
                          </div>
                        </div>
                      </li>
                      
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="primary"
                            >
                              <i className="ni ni-check-bold" />
                            </Badge>
                          </div>
                          <div>
                            <p className="mb-0">
                              Apply directly to the providers website
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                </>
              )
            }
          </div>
        </Col>
      </Row>
    </Container>
  </section>
  );
}

export default Features;
