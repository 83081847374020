import React from 'react';

import { Reviews, Providers } from '../common';
import { Hero, ComparisionTableOSHC, ComparisionTableOVHC } from "."; 

const Quote = (props) => {
  const { coverType } = props.match.params;
  return (
    <main>
        <Hero type={coverType}/>
        {
          coverType === 'oshc' && (<ComparisionTableOSHC {...props} />)
        }
        {
          coverType === 'ovhc' && (<ComparisionTableOVHC {...props} />)
        }
        
        <Providers />
        <Reviews />
      </main>
  );
}

export default Quote;
