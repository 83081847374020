import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications'
import { Redirect } from 'react-router-dom';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
  } from "reactstrap";
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import useForm from 'react-hook-form';

import Header from "../../../Headers/SlimHeader";

const visaOptions = [
    {value: '500 - Student Visa', label: '500 - Student Visa'},
    {value: '600 - Visitor Visa (Visitor)', label: '600 - Visitor Visa (Visitor)'},
    {value: '482 - Temporary Skill Shortage Visa (TSS)', label: '482 - Temporary Skill Shortage Visa (TSS)'},
    {value: '485 - Temporary Graduate', label: '485 - Temporary Graduate'},
    {value: '417 - Working Holiday', label: '417 - Working Holiday'}
];

const EditHealthCoverType = (props) => {
    const { register, handleSubmit, errors, setValue } = useForm();
    const { currentHealthCoverType, healthCoverTypeUpdated, history } = props;
    const [formData, setFormData] = useState(currentHealthCoverType);
    const { addToast } = useToasts()


    useEffect(() => {
      setFormData(currentHealthCoverType);
    }, [currentHealthCoverType]);


    useEffect(() => {
      if(healthCoverTypeUpdated) {
        addToast('Successfully updated', { appearance: 'success', autoDismiss: true, autoDismissTimeout : 3000 });
        history.push('/admin/health-cover-type');
      }
    }, [healthCoverTypeUpdated, addToast, history]);


    const onSubmit = async ({name, fullName, intro, bannerTitle, bannerIntro, supportedVisas}) => {
      const { updateCurrentHealthCoverType } = props;
      const updatedHealthCoverType = {name, fullName, intro, bannerTitle, bannerIntro, supportedVisas};
      updateCurrentHealthCoverType({healthCoverType: {...currentHealthCoverType, ...updatedHealthCoverType, supportedVisas: JSON.stringify(supportedVisas)}});
    }


    const updateInput = (event) => {
      setFormData({...formData, [event.target.name]: event.target.value})
    }


    const selectInput = (selected, name) => {
      const selectedValues = selected.map(item => item.value);
      setFormData({...formData, [name]: selectedValues});
      setValue('supportedVisas', selectedValues, true);
    }


    if(!currentHealthCoverType) return (<Redirect to="/admin/health-cover-type" />);

    const {name, fullName, intro, bannerTitle, bannerIntro, supportedVisas} = formData;
    
    
    return (
        <>
            <Header />
            <Container fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-white shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edit Health Cover Type</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="grey"
                        onClick={history.goBack}
                        size="sm"
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <FormGroup className={errors.name && "has-danger" }>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="name"
                              type="text"
                              innerRef={register({required: true})}
                              value={name}
                              onChange={updateInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className={errors.fullName && "has-danger" }>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Full Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="fullName"
                              type="text"
                              innerRef={register({required: true})}
                              value={fullName}
                              onChange={updateInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup className={errors.intro && "has-danger" }>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Intro
                            </label>
                            <Input
                                className="form-control-alternative"
                                rows="4"
                                type="textarea"
                                name="intro"
                                innerRef={register({required: true})}
                                value={intro}
                                onChange={updateInput}
                                />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className={errors.bannerTitle && "has-danger" }>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Banner title
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="bannerTitle"
                              type="text"
                              innerRef={register({required: true})}
                              value={bannerTitle}
                              onChange={updateInput}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup className={errors.bannerIntro && "has-danger" }>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Banner intro
                            </label>
                            <Input
                                className="form-control-alternative"
                                rows="4"
                                value={bannerIntro}
                                name="bannerIntro"
                                type="textarea"
                                innerRef={register({required: true})}
                                onChange={updateInput}
                                />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Supported Visas
                            </label>
                            <Select
                                value={supportedVisas.map(supportedVisa => ({label: supportedVisa, value: supportedVisa}))}
                                isMulti
                                name="supportedVisas"
                                options={visaOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                ref={() => register({name: 'supportedVisas', required: true})}
                                onChange={event => selectInput(event, 'supportedVisas')}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                          <Col lg="12">
                          <hr className="my-4" />
                            <div className="text-right">
                                <Button
                                    color="grey"
                                    type="button"
                                    onClick={history.goBack}
                                    >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    type="submit"
                                    >
                                    Update
                                </Button>
                               
                            </div>
                          </Col>
                      </Row>
                    </div>
                   
                    </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        </>
    );
}

export default withRouter(EditHealthCoverType);