import * as c from './constants';

const initialState = {
  loadingAll: false,
  error: null,
  data: [],
  deletingItem: false,
};


const actionHandlers = {
  [c.GET_LEADS_START]: (state) => {
    return {
      ...state,
      loadingAll: true,
    }
  },
  [c.GET_LEADS_SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingAll: false,
      data: action.data,
      total: action.total
    }
  },
  [c.GET_LEADS_FAIL]: (state, action) => {
    return {
      ...state,
      loadingAll: false,
      error: action.error
    }
  },

  [c.DELETE_LEAD_START]: (state) => {
    return {
      ...state,
      deletingItem: true
    }
  },
  [c.DELETE_LEAD_SUCCESS]: (state, action) => {
    return {
      ...state,
      deletingItem: false,
      data: state.data.filter(item => item.id !== action.id)
    }
  },
  [c.DELETE_LEAD_FAIL]: (state) => {
    return {
      ...state,
      deletingItem: false
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
