import React from 'react';
import moment from 'moment';

const CoverDetails = (props) => {
    const { application, coverType } = props;
    
    return (
        <>
            <div className="application-sidebar">
                
                <div className='sidebar-provider-logo'>
                    <img src={application.HealthCover.Provider && application.HealthCover.Provider.logo} alt={application.HealthCover.name} />
                </div>
                <h2><strong>${application.premium}</strong></h2>
                <h3>{application.HealthCover.name}</h3>
                <div>
                    <p>
                        Type: <span>{application.clientType}</span> <br />
                        Childrens: <span>{application.children}</span> <br />
                        Cover start date: <span>{moment(application.startDate).format('YYYY-MM-DD')}</span> <br />
                        {
                            coverType === 'oshc' && (
                                <>
                                    Cover end date: <span>{moment(application.endDate).format('YYYY-MM-DD')}</span>
                                </>
                            )
                        }
                        
                    </p>
                </div>
            </div>
        </>
    );
}

export default CoverDetails;