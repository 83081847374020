import React, {useState} from 'react';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Col,
    DropdownItem
  } from "reactstrap";
  import { Alert } from "reactstrap";
  import Avatar from 'react-avatar';
  import Rating from 'react-rating';
  import ReactPaginate from 'react-paginate';

import DeleteModal from '../../DeleteModal';
import Header from "../../Headers/SlimHeader";

const Review = (props) =>{
    const { total, data, deleteItem, getAll, submit, authUser } = props;
    const [deleteItemId, setDeleteItemId] = useState();
    const limit = 10;
    const pageCount = Math.ceil(total / limit);

    const confirmDelete = ({event, id}) => {
      event.preventDefault();
      setDeleteItemId(id)
    }

    const del = () => {
        deleteItem({id: deleteItemId});
        setDeleteItemId(null);
    }

    const paginationChange = (data) => {
      getAll({limit, offset: data.selected})
    }

    const updateReview = ({event, id, approved}) => {
      event.preventDefault();
      
      submit({data: {id, approved}});
    }

    
    return (
        <>
             <Header />
             <Container fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col sm={6}>
                      <h3 className="mb-0">All reviews</h3> 
                    </Col>
                  </Row>
                  
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">&nbsp;</th>
                      <th scope="col">Rating</th>
                      <th scope="col">Name</th>
                      <th scope="col">Account</th>
                      <th scope="col">Review</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data && data.map(({id, rating, Application, approved,  review}) => (
                        <tr key={id} style={{backgroundColor: approved ? '#ffffff' : '#f2f2f2'}}>
                          <td>
                            <Avatar name={`${Application.firstName} ${Application.lastName}`} size="40" round={true} />
                          </td>
                          <th>
                            <Rating
                                stop={5}
                                emptySymbol={['fa fa-star-o star-active']}
                                fullSymbol={['fa fa-star  star-active']}
                                initialRating={rating}
                                readonly={true}
                                />
                          </th>
                          <td>{Application.firstName} {Application.lastName}</td>
                          <td>{Application.Account ? Application.Account.name : '-'}</td>
                          <td style={{whiteSpace: 'unset'}}>
                            {review}...
                          </td>
                         
                          <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    role="button"
                                    href="#"
                                    size="sm"
                                    color=""
                                    onClick={e => e.preventDefault()}
                                  >
                                    <i className="fa fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    {
                                      !approved && (
                                        <DropdownItem 
                                      onClick={(event) => updateReview({event, id, approved: true})}
                                    >
                                      Approve
                                    </DropdownItem>
                                      )
                                    }
                                    {
                                      approved && (
                                        <DropdownItem 
                                      onClick={(event) => updateReview({event, id, approved: false})}
                                    >
                                      Disapprove
                                    </DropdownItem>
                                      )
                                    }
                                    {
                                      authUser.role ==='superadmin' && (
                                        <DropdownItem 
                                          onClick={(event) => confirmDelete({event, id})}
                                        >
                                          Delete
                                        </DropdownItem>
                                      )
                                    }
                                    
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                </Table>
                <CardFooter className="py-4">

                {
                  total > limit && (
                    <ReactPaginate
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={10}
                      pageRangeDisplayed={5}
                      onPageChange={paginationChange}
                      containerClassName={'pagination justify-content-end mb-0'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      activeClassName={'active'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      previousLabel="«"
                      nextLabel="»"
                    />
                  )
                }
                  
                {
                  (!data || !data.length) && (
                      <Alert color="info">
                        <strong>No data</strong> You don't have any data yet!
                      </Alert>
                  )
                }

                {
                  deleteItemId && (<DeleteModal showModal={deleteItemId} toggleModal={() => setDeleteItemId()} confirmAction={del} title="Delete this account?" details="Are you sure you want to delete this review?" />)
                  
                }
                    
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        </>
    );
}

export default Review;