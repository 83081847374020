import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as QueryString from 'query-string';

import * as actions from './actions'
import Application from './Application';

class ApplicationContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountId: null
    }
  }

  componentDidMount() {
    const params = QueryString.parse(window.location.search);
    const {actions: {getAll}} = this.props;
    const filter = {};
    if(params.accountId) {
      filter.accountId = params.accountId;
      this.setState({accountId: params.accountId});
    }
    getAll({limit: 20, offset: 0, filter})
  }

  render() {
    const { data, total,authUser, loadingAll,  actions: { deleteItem, getAll, submit } } = this.props;
    const { accountId } = this.state;
    return (
      <Application
        {...this.props}
        data={data}
        deleteItem={deleteItem}
        total={total}
        getAll={getAll}
        submit={submit}
        authUser={authUser}
        accountId={accountId}
        loadingAll={loadingAll}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Admin.Application,
    authUser: state.Admin.AuthUser.authUser
  }
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedApplication = connect(mapStateToProps, mapDispatchToProps)(ApplicationContainer);

export { ConnectedApplication as Application };

