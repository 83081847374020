import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import * as actions from './CurrentAccount/actions';
import Front from './Front';

class FrontContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { data, actions : { getCurrent } } = this.props;

    if(!data) {
      if(window.localStorage.getItem('agent')) {
        const slug = localStorage.getItem('agent');
        getCurrent({slug});
      }
      else {
        const url = this.props.location.search;
        if(url.indexOf('agent=') > -1) {
            const slug = url.substring(url.indexOf('agent=') + 6);
            window.localStorage.setItem('agent', slug);
            if(slug) {
                
                getCurrent({slug});
            }
            
        }
      }
    }
  }

  render() {
    const { data } = this.props;
    return (
      <Front
        currentAccount={data}
      />
    )
  }
}


const mapStateToProps = state => {
    return state.Front.CurrentAccount
}
  

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  


const ConnectedFront = connect(mapStateToProps, mapDispatchToProps)(withRouter(FrontContainer));

export { ConnectedFront as Front };

