import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions'
import Lead from './Lead';

class LeadContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.actions.getAll({limit: 10, offset: 0}); 
  }

  render() {
    const { data, total, actions: { deleteItem, getAll } } = this.props;
    return (
      <Lead
        {...this.props}
        data={data}
        deleteItem={deleteItem}
        getAll={getAll}
        total={total}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Admin.Lead,
    authUser: state.Admin.AuthUser.authUser
  }
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedLead = connect(mapStateToProps, mapDispatchToProps)(LeadContainer);

export { ConnectedLead as Lead };

