import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container,  Button, Card, Row, Col  } from "reactstrap";
import { useHistory } from "react-router-dom";

import { Loader } from '../../../Common';
import { EmailQuote } from '../../common';

const ComparisionTableOVHC = (props) => {
  const history = useHistory();
  const { quotes, loadingQuotes } = props;
  const { coverType } = props.match.params


  const buyNow = ({item}) => {
    const { updateApplication }  = props;

    updateApplication({application: {HealthCover: item.HealthCover, premium: item.premium, healthCoverId: item.HealthCover.id}});
    history.push(`/cover-type/${coverType}/application`);
  }

  if(loadingQuotes) return (
    <Container style={{width: '100%', maxWidth: 1400,}}>
      <Row className="text-center mt-lg mb-lg">
          <Col lg="12">
            <Loader />
          </Col>
      </Row>
    </Container>
  );


  if(!quotes) return null;
  
  return (
    <section className="py-sm">
        <Container style={{width: '100%', maxWidth: 1400}}>
        <EmailQuote type="ovhc" />
            <Row>
                {
                  quotes.map((item, index) => (
                    <Col  md="3" key={`quote_${index}`}>
                      <Card className="p-sm mb-sm">
                        <div className="text-center" key={item.HealthCover.id}>
                          <img alt='' width={150} src={item.HealthCover.Provider.logo} /> 
                          <div className="mt-2"><strong>{item.HealthCover.name}</strong></div>
                        </div>
                        <div className="text-center">
                          <h2 className="text-center">${item.premium}<small>/month</small></h2> 
                          <Button
                            className={coverType === 'oshc' ? "bg-gradient-primary" : "bg-gradient-secondary"}
                            color={coverType === 'oshc' ? 'primary' : 'secondary'}
                            size="md"
                            onClick={() => buyNow({item})}
                          >
                          BUY NOW
                          </Button>
                        </div>
                        </Card>
                      </Col>
                  ))
                }
          </Row>
        </Container>
    </section>
  );
}

export default withRouter(ComparisionTableOVHC);
