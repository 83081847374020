import React, {useState, useEffect} from 'react';
import {
    Container,
    Row,
    Col,
    TabPane,
    Form,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input
  } from "reactstrap";
  import { Debounce } from 'react-throttle';

import Header from "../../Headers/SlimHeader";
import ApplicationTabs from './ApplicationTabs';
import ApplicationGrid from './ApplicationGrid';

const Application = (props) =>{
    const { data, total, deleteItem, getAll, authUser,  submit, accountId, loadingAll } = props;
    const [deleteItemId, setDeleteItemId] = useState();
    const [offset, setOffset] = useState(0);
    const [status, setStatus] = useState();
    const limit = 20;
    const pageCount = Math.ceil(total / limit);
    const confirmDelete = ({event, id}) => {
      event.preventDefault();
      setDeleteItemId(id)
    }

    useEffect(() => {    
      let id = setInterval(() => {      
        const filter = {};
        if(status) {
          filter.status = status;
        }
        getAll({limit, offset: offset, filter})
      }, 60000);    
        return () => clearInterval(id);  
      });

    const del = () => {
        deleteItem({id: deleteItemId});
        setDeleteItemId(null);
    }

    const paginationChange = ({data, status}) => {
      const filter = {};
      if(status) {
        filter.status = status;
        setStatus(status);
      }
      else {
        setStatus();
      }
      setOffset(data.selected);
      getAll({limit, offset: data.selected, filter})
    }

    const updateStatus = ({event, id, status}) => {
      event.preventDefault();
      submit({data: {id, status}});
    }

    const handleKeyPress = (event) => {
      getAll({limit: 10, offset: 0, filter: {query: event.target.value}})
    }

    
    return (
        <>
             <Header />
             <Container fluid>
             <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto search-bar">
              <FormGroup className="mb-0" >
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Debounce time="400" handler="onChange">
                    <Input placeholder="Search application" onChange={handleKeyPress}  type="text" />
                  </Debounce>
                </InputGroup>
                
              </FormGroup>
            </Form>
             <Row className="align-items-center" style={{marginTop: -34}}>
                    <Col xs="12">
                      <ApplicationTabs setStatus={setStatus} setOffset={setOffset} getAll={getAll} limit={limit} offset={0} accountId={accountId}>
                        <TabPane tabId="1">
                          <ApplicationGrid 
                            title="All applications"
                            data={data} 
                            updateStatus={updateStatus} 
                            authUser={authUser} 
                            confirmDelete={confirmDelete}
                            total={total}
                            limit={limit}
                            pageCount={pageCount}
                            paginationChange={paginationChange}
                            deleteItemId={deleteItemId}
                            setDeleteItemId={setDeleteItemId}
                            del={del}
                            offset={offset}
                            loadingAll={loadingAll}
                            />
                        </TabPane>
                        <TabPane tabId="2">
                          <ApplicationGrid 
                            title="In Progress applications"
                            data={data} 
                            updateStatus={updateStatus} 
                            authUser={authUser} 
                            confirmDelete={confirmDelete}
                            total={total}
                            limit={limit}
                            pageCount={pageCount}
                            paginationChange={paginationChange}
                            deleteItemId={deleteItemId}
                            setDeleteItemId={setDeleteItemId}
                            del={del}
                            status="inProgress"
                            offset={offset}
                            loadingAll={loadingAll}
                            />
                        </TabPane>
                        <TabPane tabId="3">
                          <ApplicationGrid 
                            title="Payment made applications"
                            data={data} 
                            updateStatus={updateStatus} 
                            authUser={authUser} 
                            confirmDelete={confirmDelete}
                            total={total}
                            limit={limit}
                            pageCount={pageCount}
                            paginationChange={paginationChange}
                            deleteItemId={deleteItemId}
                            setDeleteItemId={setDeleteItemId}
                            del={del}
                            status="paymentMade"
                            offset={offset}
                            loadingAll={loadingAll}
                            />
                        </TabPane>
                        <TabPane tabId="4">
                          <ApplicationGrid 
                            title="Certificate issued applications"
                            data={data} 
                            updateStatus={updateStatus} 
                            authUser={authUser} 
                            confirmDelete={confirmDelete}
                            total={total}
                            limit={limit}
                            pageCount={pageCount}
                            paginationChange={paginationChange}
                            deleteItemId={deleteItemId}
                            setDeleteItemId={setDeleteItemId}
                            del={del}
                            status="certificateIssued"
                            offset={offset}
                            loadingAll={loadingAll}
                            />
                        </TabPane>
                      </ApplicationTabs>
                    </Col>
              </Row>
        </Container>
        </>
    );
}

export default Application;