import React from 'react';

import {
    Button,
    Modal,
  } from "reactstrap";

const DeleteModal = (props) => {
    const { showModal, title, details, toggleModal, confirmAction} = props;

    return (
        <Modal
              className="modal-dialog-centered"
              isOpen={showModal ? true : false}
              toggle={toggleModal}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="modal-title-default">
                  {title}
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleModal}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  {details}
                </p>
              </div>
              <div className="modal-footer">
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={toggleModal}
                >
                  CANCEL
                </Button>
                <Button color="primary" type="button" onClick={confirmAction}>
                  DELETE
                </Button>
              </div>
            </Modal>
    );
}

export default DeleteModal;