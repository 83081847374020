import React from 'react';
import {Elements} from 'react-stripe-elements';

import { Reviews, Providers } from '../common';
import { Steps } from '../common';
import { PaymentForm } from './PaymentForm';

const Payment = (props) => {
  const { coverType } = props.match.params;
  
  return (
    <main>
        <Steps currentStep={3} type={coverType}/>
        <Elements>
          <PaymentForm {...props} coverType={coverType} />
        </Elements>
        <Providers />
        <Reviews />
      </main>
  );
}

export default Payment;
