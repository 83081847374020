import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../actions';
import AccountForm from './AccountForm';

class AccountFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { actions: { getCurrent, resetCurrentItem } } = this.props;

    if (id) {
      getCurrent({ id });
    } else {
      resetCurrentItem();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
      const { history } = this.props;
      history.push('/admin/accounts');
    }
  }

  render() {
    const { currentItem, actions: { submit } } = this.props;
    return (
      <AccountForm
        {...this.props}
        currentItem={currentItem}
        submit={submit}
      />
    );
  }
}

const mapStateToProps = (state) => state.Admin.Account;

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});


const ConnectedAccountForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountFormContainer));

export { ConnectedAccountForm as AccountForm };
