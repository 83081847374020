import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, InputGroup, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import useForm from 'react-hook-form';
import * as yup from 'yup';

import { titleOptions, } from './constants';

import { StyledDropdown } from '../../common';
import { CoverDetails } from '../../common';

const applicationFormSchema = {
    title: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    mobile: yup.string().required('Mobile is required').matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, 'Invalid mobile number'),
    
};

const ApplicationForm = ( props ) => {
    const { application } = props;
    const [formData, setFormData] = useState(application);

    const { register, handleSubmit, clearError, setValue, errors } = useForm({
        validationSchema: yup.object().shape(applicationFormSchema),
        defaultValues: application,
    });

    const handleSelectChange = async (selectedOption, name) => {
        const value = selectedOption.value ? selectedOption.value : selectedOption.name
        const changeData = { [name]:  value};
        setFormData({...formData, ...changeData});
        setValue(name, value);
        clearError(name)
        
    };

    const handleInputChange = (event) => {
        const { target } = event;
        let name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value
        setFormData({...formData, [name]:  value})
        setValue(name, value)
        clearError(name)
    }  

    
    const onSubmit = data => {
        const { updateApplication } = props;
        updateApplication({application: {...application, ...data}})
    }

    useEffect(() => {
        register({name: 'title'});
        register({name: 'firstName'});
        register({name: 'lastName'});
        register({name: 'email'});
        register({name: 'mobile'});
        register({name: 'country'});
    }, [register]);

    return (
        <section className="section">
             <Container>
                   <Row>
                        <Col lg="8">
                            <Row>
                                <Col lg="6">
                                    <h2>Personal Details</h2>
                                </Col>
                                <Col lg="6" className="text-right">
                                   <span className="form-info"> <span className="form-error">*</span> Indicates required field</span>
                                </Col>
                            </Row>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col lg="3">
                                        <FormGroup className="form-item">
                                            <Label>Title<span className="form-error">*</span></Label>
                                            <InputGroup className="input-group-alternative">
                                                <StyledDropdown
                                                    selected={titleOptions.find(item => item.value === formData.title)} 
                                                    onChange={(selected) => handleSelectChange(selected, 'title')} 
                                                    name="title"
                                                    options={titleOptions} placeholder='Select' />
                                            </InputGroup>
                                            {errors.title && (<span className="form-error">Title is required</span>)}
                                        </FormGroup>
                                    </Col>
                                   
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup className="form-item">
                                            <Label>First name<span className="form-error">*</span></Label>
                                            <InputGroup className="input-group-alternative">
                                                <Input
                                                    className="form-control-alternative"
                                                    name="firstName"
                                                    type="text"
                                                    value={formData.firstName}
                                                    onChange={handleInputChange}
                                                    />
                                            </InputGroup>
                                            {errors.firstName && (<span className="form-error">First name is required</span>)}
                                            
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup className="form-item">
                                            <Label>Last name<span className="form-error">*</span></Label>
                                            <InputGroup className="input-group-alternative">
                                                <Input
                                                    className="form-control-alternative"
                                                    name="lastName"
                                                    type="text"
                                                    value={formData.lastName}
                                                    onChange={handleInputChange}
                                                    />
                                            </InputGroup>
                                            {errors.lastName && (<span className="form-error">Last name is required</span>)}
                                            
                                        </FormGroup>
                                    </Col>
                                </Row>

                               

                                <Row>
                                    <Col lg="6">
                                        <FormGroup className="form-item">
                                            <Label>Email address<span className="form-error">*</span></Label>
                                            <InputGroup className="input-group-alternative">
                                                <Input
                                                    className="form-control-alternative"
                                                    name="email"
                                                    type="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    />
                                            </InputGroup>
                                            {errors.email && (<span className="form-error">Email address is required</span>)}
                                        </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                        <FormGroup className="form-item">
                                            <Label>Mobile number<span className="form-error">*</span></Label>
                                            <InputGroup className="input-group-alternative">
                                                <Input
                                                    className="form-control-alternative"
                                                    name="mobile"
                                                    type="text"
                                                    value={formData.mobile}
                                                    onChange={handleInputChange}
                                                    />
                                            </InputGroup>
                                            {errors.mobile && (<span className="form-error">{errors.mobile.message}</span>)}
                                        </FormGroup>
                                    </Col>
                                    
                                </Row>

                                <Row>
                                    <Col lg="12">
                                    <hr />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg="12">
                                    <div  className="form-action text-right">
                                        <Link to="/cover-type/ovhc/quote">
                                            Back
                                        </Link>

                                        &nbsp;

                                        <Button
                                            className="bg-gradient-secondary"
                                            color="secondary"
                                            size="lg"
                                            type="submit"
                                            style={{marginLeft: 15}}
                                            >
                                             Continue
                                        </Button>
                                    </div>
                                    </Col>
                                </Row>

                            </Form>
                        </Col>
                        <Col lg="4">
                            <CoverDetails application={application} />
                        </Col>
                   </Row>
              </Container>
        </section>
    );
}

export default ApplicationForm;