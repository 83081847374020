import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Landing, CoverType, Quote, Application, Payment, Confirmation, Review, MyCover, AboutUs, PrivacyPolicy, ComplaintsPolicy, FAQs } from '.';
import { Header, Footer } from './common';

require("../../assets/scss/front/design-system-react.scss");

const Front = (props) => {
  const { currentAccount } = props;
  return (
     <>
          <Header currentAccount={currentAccount} />
          <main>
            <Switch>
                <Route path="/cover-type/:coverType/quote" exact component={Quote} />
                <Route path="/cover-type/:coverType/application" exact component={Application} />
                <Route path="/cover-type/:coverType/application/:applicationId" exact component={Application} />
                <Route path="/cover-type/:coverType/application/:applicationId/payment" exact component={Payment} />
                <Route path="/cover-type/:coverType/application/:applicationId/confirmation" exact component={Confirmation} />
                <Route path="/cover-type/:coverType" exact component={CoverType} />
                <Route path="/reviews" exact component={Review} />
                <Route path="/faqs" exact component={FAQs} />
                <Route path="/about-us" exact component={AboutUs} />
                <Route path="/privacy-policy" exact component={PrivacyPolicy} />
                <Route path="/complaints-policy" exact component={ComplaintsPolicy} />
                <Route path="/my-cover" exact component={MyCover} />
                <Route path="/" component={Landing} />
            </Switch>
          </main>
          <Footer />
      </>
  );
}

export default Front;
