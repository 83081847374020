import client from '../../../../client';

const apiSlug = `health-cover-features`;

function getAll({offset, limit}) {
    return client.get(`${apiSlug}?offset=${offset}&limit=${limit}`);
}

function update({data}) {
    return client.put(`${apiSlug}/${data.id}`, data);
}

function add({data}) {
    return client.post(apiSlug, data);
}

function del({id}) {
    return client.delete(`${apiSlug}/${id}`);
}

function getOne({id}) {
    return client.get(`${apiSlug}/${id}`);
}

export { getAll, update,  add, del, getOne };