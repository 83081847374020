import React from 'react';
import { Container, Row, Col,
    Card,
    CardBody  } from "reactstrap";
import Rating from 'react-rating';
import moment from 'moment';

import { Reviews, Providers } from '../common';
import { Loader } from '../../Common';

const Review = (props) => {
  const { data, loadingAll } = props;
  return (
    <main>
        <div className="position-relative">
          <section className="section section-shaped pb-16">
            <div className="shape shape-primary">
              &nbsp;
            </div>
          </section>
        </div>
        <section className="section">
            <Container>
                <Row>
                    <Col lg="8">
                        <h2>Reviews from real customers</h2>
                    </Col>
                    </Row>
                    {
                        loadingAll && (
                            <Row>
                                <Col lg="12" className="text-center">
                                    <Loader />
                                </Col>
                            </Row>
                        )
                    }
                    <Row>
                    
                {
                    data.map(item => (
                        <Col lg="12" key={item.id}>
                            <Card className="  mt-4">
                                <CardBody>
                                    <div className="mb-2"><Rating
                                            stop={5}
                                            emptySymbol={['fa fa-star-o star-active']}
                                            fullSymbol={['fa fa-star  star-active']}
                                            initialRating={item.rating}
                                            readonly={true}
                                            />
                                    </div>
                                    <div>
                                    <p>
                                        {item.review}
                                    </p>
                                   
                                    <div><strong>{item.Application && item.Application.firstName} {item.Application && item.Application.lastName}</strong></div>
                                    
                                    <small><em>{moment(item.createdAt).format('MMMM YYYY')}</em></small>
                                    </div>
                                </CardBody>
                            </Card>
                            </Col>
                    ))
                }
                </Row>
                
            </Container>
        </section>
        <Providers />
        <Reviews />
      </main>
  );
}

export default Review;
