import * as c from './constants';

const initialState = {
  loadingQuotes: false,
  error: null,
  data: null,
};


const actionHandlers = {
  [c.GET_QUOTES_START]: (state) => {
    return {
      ...state,
      loadingQuotes: true,
    }
  },
  [c.GET_QUOTES_SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingQuotes: false,
      quotes: action.quotes,
      featureCategories: action.featureCategories,
    }
  },
  [c.GET_QUOTES_FAIL]: (state, action) => {
    return {
      ...state,
      loadingQuotes: false,
      error: action.error
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
