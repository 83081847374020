import * as c from './constants';
import * as leadService from './services';

const getAllStart = () => ({
  type: c.GET_LEADS_START
})

const getAllSuccess = ({data, total}) => ({
  type: c.GET_LEADS_SUCCESS,
  data,
  total
})

const getAllFail = ({error}) => ({
  type: c.GET_LEADS_FAIL,
  error,
})

export const getAll = ({limit, offset}) => async (dispatch) => {
  dispatch(getAllStart())
  try {
      const {data} = await leadService.getAll({limit, offset});
      dispatch(getAllSuccess({data: data.data, total: data.total}));
  } catch (error) {
    dispatch(getAllFail({error}))
  }
}


const deleteStart = () => ({
  type: c.DELETE_LEAD_START
})
const deleteSuccess = ({id}) => ({
  type: c.DELETE_LEAD_SUCCESS,
  id,
})
const deleteFail = ({error}) => ({
  type: c.DELETE_LEAD_FAIL,
  error,
})
export const deleteItem = ({id}) => async (dispatch) => {
  dispatch(deleteStart())
  try {
      await leadService.del({id});
      dispatch(deleteSuccess({id}));
  } catch (error) {
    dispatch(deleteFail({error}))
  }
}

