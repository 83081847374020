export const GET_APPLICATIONS_START = 'GET_APPLICATIONS_START';
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
export const GET_APPLICATIONS_FAIL = 'GET_APPLICATIONS_FAIL';

export const GET_CURRENT_APPLICATION_START = 'GET_CURRENT_APPLICATION_START';
export const GET_CURRENT_APPLICATION_SUCCESS = 'GET_CURRENT_APPLICATION_SUCCESS';
export const GET_CURRENT_APPLICATION_FAIL = 'GET_CURRENT_APPLICATION_FAIL';

export const SUBMIT_APPLICATION_START = 'SUBMIT_APPLICATION_START';
export const SUBMIT_APPLICATION_SUCCESS = 'SUBMIT_APPLICATION_SUCCESS';
export const SUBMIT_APPLICATION_FAIL = 'SUBMIT_APPLICATION_FAIL';

export const DELETE_APPLICATION_START = 'DELETE_APPLICATION_START';
export const DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCESS';
export const DELETE_APPLICATION_FAIL = 'DELETE_APPLICATION_FAIL';

export const DELETE_CERTIFICATE_START = 'DELETE_CERTIFICATE_START';
export const DELETE_CERTIFICATE_SUCCESS = 'DELETE_CERTIFICATE_SUCCESS';
export const DELETE_CERTIFICATE_FAIL = 'DELETE_CERTIFICATE_FAIL';

export const ASSIGN_USER_START = 'ASSIGN_USER_START';
export const ASSIGN_USER_SUCCESS = 'ASSIGN_USER_SUCCESS';
export const ASSIGN_USER_FAIL = 'ASSIGN_USER_FAIL';
