import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as userActions from '../../Views/User/actions';
import * as applicationActions from '../../Views/Application/actions';
import Assign from './Assign';

class AssignContainer extends Component {

  componentDidMount(){
    const { actions: { getAll} } = this.props;
    getAll({ limit: 1000, offset: 0, filter: {} });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.assigningUser && !this.props.assigningUser && !this.props.error) {
      this.props.setAssignApplication(null);
    }
  } 

  render() {
    const { loadingUsers, authUser, application,  users, id,  setAssignApplication, assigningUser, actions: {assignUser}} = this.props;

    return (
      <Assign
        {...this.props}
        loadingUsers={loadingUsers}
        users={users}
        id={id}
        setAssignApplication={setAssignApplication}
        authUser={authUser}
        assigningUser={assigningUser}
        application={application}
        assignUser={assignUser}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    loadingUsers: state.Admin.User.loadingAll,
    users: state.Admin.User.data,
    authUser: state.Admin.AuthUser.authUser,
    assigningUser: state.Admin.Application.assigningUser,
  }
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators({getAll: userActions.getAll, assignUser: applicationActions.assignUser}, dispatch)
    }
  }
  

const ConnectedAssign = connect(mapStateToProps, mapDispatchToProps)(AssignContainer);

export { ConnectedAssign as Assign };

