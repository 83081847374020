import * as c from './constants';

const initialState = {
  loadingAll: false,
  error: null,
  data: [],
  currentItem: { name: '', email: '', address: '' },
  submittingForm: false,
  deletingItem: false,
};

const actionHandlers = {
  [c.GET_ACCOUNTS_START]: (state) => ({
    ...state,
    loadingAll: true,
  }),
  [c.GET_ACCOUNTS_SUCCESS]: (state, action) => ({
    ...state,
    loadingAll: false,
    data: action.data,
    total: action.total,
  }),
  [c.GET_ACCOUNTS_FAIL]: (state, action) => ({
    ...state,
    loadingAll: false,
    error: action.error,
  }),

  [c.GET_CURRENT_ACCOOUNT_START]: (state) => ({
    ...state,
    loadingAll: true,
    error: null,
  }),
  [c.GET_CURRENT_ACCOUNT_SUCCESS]: (state, action) => ({
    ...state,
    loadingAll: false,
    currentItem: action.currentItem,
    error: null,
  }),
  [c.GET_CURRENT_ACCOUNT_FAIL]: (state, action) => ({
    ...state,
    loadingAll: false,
    error: action.error,
  }),

  [c.RESET_CURRENT_ACCOUNT_START]: (state) => ({
    ...state,
    currentItem: initialState.currentItem,
  }),

  [c.SUBMIT_ACCOUNT_START]: (state) => ({
    ...state,
    submittingForm: true,
  }),
  [c.SUBMIT_ACCOUNT_SUCCESS]: (state, action) => {
    const alreadyExists = state.data.find((item) => item.id === action.data.id);
    let udpatedData;
    if (!alreadyExists) {
      udpatedData = [action.data, ...state.data];
    } else {
      udpatedData = state.data.map((item) => {
        if (item.id === action.data.id) {
          return action.data;
        }
        return item;
      });
    }

    return {
      ...state,
      submittingForm: false,
      data: udpatedData,
      currentItem: initialState.currentItem,
    };
  },
  [c.SUBMIT_ACCOUNT_FAIL]: (state, action) => ({
    ...state,
    submittingForm: false,
    error: action.error,
  }),

  [c.DELETE_ACCOUNT_START]: (state) => ({
    ...state,
    deletingItem: true,
  }),
  [c.DELETE_ACCOUNT_SUCCESS]: (state, action) => ({
    ...state,
    deletingItem: false,
    data: state.data.filter((item) => item.id !== action.id),
  }),
  [c.DELETE_ACCOUNT_FAIL]: (state) => ({
    ...state,
    deletingItem: false,
  }),
};

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
};
