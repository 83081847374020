import React, { useState } from 'react';
import { TabContent, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const ApplicationTabs = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
      const { limit, setOffset, accountId, setStatus} = props;
      if(activeTab !== tab){
        const filter = {}
        if(accountId) {
          filter.accountId = accountId;
        }
        
        switch(tab) {
          case '1':
            props.getAll({limit, offset: 0, filter});
            break;
          case '2':
            filter.status = 'inProgress';
            props.getAll({limit, offset: 0, filter});
            break;
          case '3':
            filter.status = 'paymentMade';
            props.getAll({limit, offset: 0, filter});
            break;
          case '4':
            filter.status = 'certificateIssued';
            props.getAll({limit, offset: 0, filter});
            break;
          default: 
            props.getAll({limit, offset: 0, filter});
        }
        setStatus(filter.status);
        setOffset(0);
        setActiveTab(tab);
      };
    }
    return (
      <div>
        <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            All
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            In Progress
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            Payment Made
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => { toggle('4'); }}
          >
            Certificate Issued
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        {props.children}
      </TabContent>
      </div>
    );
}

export default ApplicationTabs;