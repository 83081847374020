import React, {useState} from 'react';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Row,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Col,
    DropdownItem,
    Badge,
  } from "reactstrap";
  import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Avatar from 'react-avatar';
import moment from 'moment';

import DeleteModal from '../../DeleteModal';
import { ExportApplication, Assign } from '../../Components';
import { Loader } from '../../../Common';

const ApplicationGrid = ({
    data, 
    updateStatus, 
    authUser, 
    confirmDelete, 
    total, 
    limit, 
    pageCount, 
    paginationChange, 
    deleteItemId, 
    setDeleteItemId, 
    del,
    title,
    status,
    offset,
    loadingAll
}) => {

    const [assignApplication, setAssignApplication] = useState(null);

    const showAssignModal = ({event, application}) => {
        event.preventDefault();
        setAssignApplication(application)
    }
    return (
        <Row>
            <div className="col">
            <Card className="shadow" >
                <CardHeader className="border-0">
                <Row>
                    <Col sm={6}>
                        <h3 className="mb-0">{title} </h3> 
                        
                    </Col>
                    {
                        (authUser.role === 'agent' || authUser.role === 'superadmin') && (<ExportApplication authUser={authUser} />)
                    }
                    
                </Row>

                
                </CardHeader>
                <Table className="align-items-center table-flush" responsive  >
                <thead className="thead-light">
                    <tr>
                    <th scope="col" width="40">Applicant</th>
                    <th scope="col">Reference No</th>
                   
                    <th scope="col">Account</th>
                    
                    <th scope="col">Date</th>
                    <th scope="col">Premium</th>
                    <th scope="col">Type</th>
                    <th scope="col">Health Cover</th>
                    <th scope="col">Assignee</th>
                    <th scope="col">Status</th>
                    <th scope="col" />
                    </tr>
                </thead>
                <tbody>
                    {
                    data && data.map(({id, User, referenceNo, createdAt, firstName, lastName, email, premium, clientType, certificate, Account, HealthCover, status}, index) => (
                        <tr key={id}>
                        <td>
                            <div style={{display: 'flex'}}>
                                <Avatar name={`${firstName} ${lastName}`} size="40" round={true} />
                                <div className="ml-2">
                                <strong>{firstName}  {lastName} </strong><br />
                                {email ? email.substring(0, 15) : '-'} {email && email.length > 15 && '...'}
                                </div>
                            </div>
                        </td>
                        <td><Link to={`/admin/applications/${id}/details`}>{referenceNo}</Link></td>
                        
                        <td>
                            {Account ? Account.name.substring(0, 10) : '-'} {Account && Account.name.length > 10 && '...'}
                        </td>
                       
                        <td>{moment(createdAt).format('DD MMM YYYY')}</td>
                        <td>
                            ${premium}
                        </td>
                        <td>{clientType}</td>
                        <td className="text-center">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={HealthCover.Provider.logo} width={40} alt={HealthCover.Provider.name} /> 
                                <div className="ml-2">{HealthCover.name}</div>
                            </div>
                            </td>
                        <td>
                            {
                                User && (
                                    <>
                                        <div style={{display: 'flex'}}>
                                            <Avatar name={`${User.firstName} ${User.lastName}`} size="40" round={true} />
                                            <div className="ml-2">
                                            <strong>{User.firstName}  {User.lastName} </strong><br />
                                            {User.email ? User.email.substring(0, 15) : '-'} {User.email && User.email.length > 15 && '...'}
                                            
                                            </div>
                                        </div>

                                        
                                    </>
                                )
                            }
                        </td>
                        <td>
                            {status === 'inProgress' && (<Badge color="info">In progress</Badge>)}
                            {status === 'paymentMade' && (<Badge color="danger">Payment made</Badge>)}
                            {status === 'certificateIssued' && (<Badge color="success">Certificate issued</Badge>)}
                        </td>
                        <td className="text-right">
                                <UncontrolledDropdown>
                                <DropdownToggle
                                    className="btn-icon-only text-light"
                                    role="button"
                                    href="#"
                                    size="sm"
                                    color=""
                                    onClick={e => e.preventDefault()}
                                >
                                    <i className="fa fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                    <Link 
                                    className="dropdown-item"
                                    to={`/admin/applications/${id}/details`}
                                    >
                                    Details
                                    </Link>
                                    {
                                    (status === 'inProgress') && User && (User.id === authUser.id)  && (
                                        <DropdownItem 
                                        onClick={(event) => updateStatus({event, id, status: 'paymentMade'})}
                                        >
                                        Mark as paid
                                        </DropdownItem>
                                    )
                                    }

                                    {
                                    certificate && (
                                        <>
                                        <DropdownItem 
                                                href={certificate}
                                                >
                                            Download policy certificate
                                            </DropdownItem>
                                        </>
                                        
                                    )
                                    }

                                    {
                                    authUser.role ==='superadmin' && status === 'inProgress' && (
                                        <DropdownItem 
                                        onClick={(event) => confirmDelete({event, id})}
                                        >
                                        Delete
                                        </DropdownItem>
                                    )
                                    }
                                    
                                    {
                                        (authUser.role ==='superadmin' || authUser.role ==='staff') && (status !== 'certificateIssued') && (
                                            <DropdownItem onClick={(event) => showAssignModal({event, application: {id, firstName, lastName, email, premium, clientType}})}>
                                                Assign
                                            </DropdownItem>
                                        )
                                    }
                                
                                </DropdownMenu>
                                </UncontrolledDropdown>
                        </td>
                        </tr>
                    ))
                    }
                    </tbody>
                </Table>
                <CardFooter className="py-4">
                <Row>
                    <Col lg="3">
                        {
                            data.length ? (
                                <small>{limit * offset + 1} - {limit * offset + data.length} of {total}</small>
                            ) : null
                        }
                        </Col>
                    <Col lg="9">
                    {
                        (total > limit) && (
                            <ReactPaginate
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageCount}
                            marginPagesDisplayed={10}
                            pageRangeDisplayed={5}
                            onPageChange={(data) => paginationChange({data, status})}
                            containerClassName={'pagination justify-content-end mb-0'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            activeClassName={'active'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            previousLabel="«"
                            nextLabel="»"
                            />
                        )
                        }
                    </Col>
                </Row>

                {
                    loadingAll ? (
                        <>
                            <Loader />
                        </>
                    ) : (
                        <>
                        {
                            (!data || !data.length) && (
                                <Alert color="info">
                                    <strong>No data</strong> You don't have any data yet!
                                </Alert>
                            )
                            }
                        </>
                    )
                }
                
               

                {
                    deleteItemId && (<DeleteModal showModal={deleteItemId} toggleModal={() => setDeleteItemId()} confirmAction={del} title="Delete this application?" details="Are you sure you want to delete this application? All data related to this data will also be deleted" />)
                }

                {
                    assignApplication && (<Assign setAssignApplication={setAssignApplication} application={assignApplication} />)
                }
                    
                </CardFooter>
            </Card>
            </div>
        </Row>
    );
}

export default ApplicationGrid;