import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, Input, Form, Button } from 'reactstrap';
import useForm from 'react-hook-form';
import * as yup from 'yup';

const formSchema = {
    comment: yup.string().required('This field is required'),
};

const ApplicationCommentForm = ({submit, submittingForm, applicationId, formData}) => {
    const [comment, setComment] = useState(formData.comment);
    const { register, handleSubmit, errors, setValue, clearError } = useForm({
        validationSchema: yup.object().shape(formSchema),
        defaultValues: formData,
    });

    useEffect(() => {
        setValue('comment', formData.comment);
        setComment(formData.comment);
    }, [formData, setValue]);

    const onSubmit = async (data) => {
        submit({data: {...data, applicationId}});
    }

    const handleInputChange = (event) => {
        const { target } = event;
        let name = target.name;
        const value = target.value;
        setValue(name, value)
        clearError(name);
        setComment(value);
    }  

    useEffect(() => {
        register({name: 'comment'});
    }, [register]);

    return (
        <Row>
            <Col lg="12">
            <div className="application-sidebar">
                <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <label
                        className="form-control-label"
                        >
                        Comment
                        </label>
                        <Input
                            className="form-control-alternative"
                            rows="4"
                            type="textarea"
                            name="comment"
                            value={comment}
                            onChange={handleInputChange}
                            />
                    </FormGroup>
                    {errors.comment && (<small className="formError">{errors.comment.message}</small>)}
               
                <Row>
                    <Col lg="12">
                    <div className="text-right mt-1 mb-2">
                        <Button
                            color="primary"
                            type="submit"
                            disabled={submittingForm? true : false}
                            >
                            SUBMIT
                        </Button>
                        
                    </div>
                    </Col>
                </Row>
                </Form>
            </div>
            </Col>
        </Row>
    );
}

export default ApplicationCommentForm;