import React, {useState} from 'react';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Button,
    Col,
    DropdownItem,
    Badge
  } from "reactstrap";
  import { Alert } from "reactstrap";
  import { Link } from "react-router-dom";
  import Avatar from 'react-avatar';
  import ReactPaginate from 'react-paginate';

import DeleteModal from '../../DeleteModal';
import Header from "../../Headers/SlimHeader";

const User = (props) =>{
    const { total, getAll, data, deleteItem, loggedInUser } = props;
    const [deleteItemId, setDeleteItemId] = useState();
    const limit = 10;
    const pageCount = Math.ceil(total / limit);

    const confirmDelete = ({event, id}) => {
      event.preventDefault();
      setDeleteItemId(id)
    }

    const del = () => {
        deleteItem({id: deleteItemId});
        setDeleteItemId(null);
    }

    const paginationChange = (data) => {
      getAll({limit, offset: data.selected})
    }
    
    return (
        <>
          <Header />
          <Container fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col sm={6}>
                      <h3 className="mb-0">All users</h3> 
                    </Col>
                    {
                      loggedInUser.role === 'superadmin' && (
                        <Col sm={6} className="text-right">
                            <Button className="btn-icon " outline size="md" href="/admin/users/new"  color="primary">
                              <span className="btn-inner--icon">
                                <i className="ni ni-fat-add" /> ADD
                              </span>
                            </Button>
                        </Col>
                      )
                    }
                    
                  </Row>
                  
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th width="30">&nbsp;</th>
                      <th scope="col">First name</th>
                      <th scope="col">Last name</th>
                      <th scope="col">Role</th>
                      <th scope="col">Email</th>
                      <th scope="col">Account</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data && data.map(({id, firstName, role, lastName, email, Account}) => (
                        <tr key={id}>
                          <td><Avatar name={`${firstName} ${lastName}`} size="40" round={true} /></td>
                          <th>
                            {firstName}
                          </th>
                          <td>
                            {lastName}
                          </td>
                          <td>
                            {
                              role === 'superadmin' && (<Badge color="danger">Super admin</Badge>)
                            }
                            
                            {
                              role === 'agent' && (<Badge color="info">Agent</Badge>)
                            }

{
                              role === 'staff' && (<Badge color="success">Staff</Badge>)
                            }
                            </td>
                          <td>
                            {email}
                          </td>
                          <td>
                            {Account ? Account.name : '-'}
                          </td>
                          <td className="text-right">
                            {
                              loggedInUser.role === 'superadmin' && (
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    role="button"
                                    href="#"
                                    size="sm"
                                    color=""
                                    onClick={e => e.preventDefault()}
                                  >
                                    <i className="fa fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    

                                    <Link 
                                      className="dropdown-item"
                                      to={`/admin/users/${id}/edit`}
                                    >
                                      Edit
                                    </Link>
                                   
                                    {
                                      loggedInUser.id !== id && (
                                        <DropdownItem 
                                          onClick={(event) => confirmDelete({event, id})}
                                        >
                                          Delete
                                        </DropdownItem>
                                      )
                                    }
                                    
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              )
                            }
                                
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                </Table>
                <CardFooter className="py-4">
                
                {
                  total > limit && (
                    <ReactPaginate
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={10}
                      pageRangeDisplayed={5}
                      onPageChange={paginationChange}
                      containerClassName={'pagination justify-content-end mb-0'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      activeClassName={'active'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      previousLabel="«"
                      nextLabel="»"
                    />
                  )
                }
                  
                {
                  (!data || !data.length) && (
                      <Alert color="info">
                        <strong>No data</strong> You don't have any data yet!
                      </Alert>
                  )
                }

                {
                  deleteItemId && (<DeleteModal showModal={deleteItemId} toggleModal={() => setDeleteItemId()} confirmAction={del} title="Delete this user?" details="Are you sure you want to delete this user? All data related to this user will also be deleted" />)
                  
                }
                    
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        </>
    );
}

export default User;