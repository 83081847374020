import React from 'react';
import { Container, Row, Col, Card, CardBody,  } from "reactstrap";

import { OSHCForm, OVHCForm } from '../index';


const Hero = ( props ) => {
  const { type } = props;

  const bannerContent = {};
  switch(type) {
    case 'oshc':
      bannerContent.title = "Best deal on Overseas Student Health Cover(OSHC)";
      bannerContent.description = "Compare Australian Government-approved OSHC providers in one place. Buy policy and have get your certificate instantly."
      break;
    case 'ovhc':
      bannerContent.title = "Best deal on Overseas Visitor Health Cover(OVHC)";
      bannerContent.description = "Compare Australian Government-approved OVHC providers in one place. Compare different options under same umbrella and choose one that meets your requirement."
      break;
    default:
  }

  return (
    <div className="position-relative">
          <section className="section section-lg section-hero section-shaped oshc-banner">
            <div className={type === 'oshc' ? "shape shape-primary" : "shape shape-secondary" }>
              &nbsp;
            </div>
            <Container className="shape-container d-flex align-items-center py-md">
              <div className="col px-0">
                <Row className="align-items-center justify-content-center">
                  <Col lg="7">
                    <h1 className="text-white">{bannerContent.title}</h1>
                    <p className="lead text-white">
                      {bannerContent.description}
                    </p>
                  </Col>
                  <Col lg="5">
                    <Card className="shadow shadow-lg--hover mt-4">
                        <CardBody>
                          {
                            type === 'oshc' && (<OSHCForm />)
                          }
                          {
                            type === 'ovhc' && (<OVHCForm />)
                          }
                        </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 200 0 200"
                />
              </svg>
            </div>
          </section>
        </div>
  );
}

export default Hero;
