import React from 'react';

import { Reviews, Providers } from '../common';
import { ApplicationFormOSHC, ApplicationFormOVHC } from "."; 
import { Steps } from '../common';

const Application = (props) => {
  const { coverType } = props.match.params;
 
  return (
    <main>
        <Steps currentStep={2} type={coverType}/>

        {
          (coverType === "oshc") && (<ApplicationFormOSHC {...props} />) 
        }
        {
          (coverType === "ovhc") && (<ApplicationFormOVHC {...props} />) 
        }
        
        <Providers />
        <Reviews />
      </main>
  );
}

export default Application;
