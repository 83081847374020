import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as QueryString from 'query-string';

import * as actions from '../../Application/actions';
import OVHCForm from './OVHCForm';

class OVHCFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const pathName = window.location.pathname;
    if (pathName === '/cover-type/oshc') {
      const { actions: { clearCurrentApplication } } = this.props;
      clearCurrentApplication();
    } else {
      const { actions: { updateApplication } } = this.props;
      const params = QueryString.parse(window.location.search);
      if (params) {
        updateApplication({
          application: params,
        });
      }
    }
  }

  render() {
    const { application, actions: { updateApplication } } = this.props;

    return (
      <OVHCForm
        {...this.props}
        application={application}
        updateApplication={updateApplication}
      />
    );
  }
}

const mapStateToProps = (state) => state.Front.Application;

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});


const ConnectedOVHCForm = connect(mapStateToProps, mapDispatchToProps)(OVHCFormContainer);

export { ConnectedOVHCForm as OVHCForm };
