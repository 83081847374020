import cogoToast from 'cogo-toast';
import * as c from './constants';
import * as services from './services';


const loginStart = () => ({
  type: c.LOGIN_START,
});

const loginSuccess = ({ token, user }) => ({
  type: c.LOGIN_SUCCESS,
  token,
  user,
});

const loginFail = ({ error }) => ({
  type: c.LOGIN_FAIL,
  error,
});


export const logIn = ({ email, password }) => async (dispatch) => {
  dispatch(loginStart());
  try {
    const { data } = await services.logIn({ email, password });
    if(data.user.is2FAEnabled) {
      dispatch(loginSuccess({ token: data.token, user: data.user }));
    }
    else {
      localStorage.setItem('token', data.token);
      window.location.href = '/admin';
    }
    dispatch(loginSuccess({ token: data.token, user: data.user }));
  } catch (error) {
    console.log(`Login fail with error: ${error}`);
    dispatch(loginFail({ error }));
  }
};


const lotoutStart = () => ({
  type: c.LOGOUT_START,
});

const logoutSuccess = () => ({
  type: c.LOGOUT_SUCCESS,
});

const logoutFail = ({ error }) => ({
  type: c.LOGOUT_FAIL,
  error,
});

export const logOut = () => async (dispatch) => {
  dispatch(lotoutStart());
  try {
    localStorage.clear();
    dispatch(logoutSuccess());
  } catch (error) {
    console.log(`Logout fail with error: ${error}`);
    dispatch(logoutFail({ error }));
  }
};


const verifyMFAStart = () => ({
  type: c.VERIFY_MFA_START,
});

const verifyMFASuccess = ({ token, user }) => ({
  type: c.VERIFY_MFA_SUCCESS,
  token,
  user,
});

const verifyMFAFail = ({ error }) => ({
  type: c.VERIFY_MFA_FAIL,
  error,
});


export const verifyMFA = ({ code, token }) => async (dispatch) => {
  dispatch(verifyMFAStart());
  try {
    const { data } = await services.verifyMFA({code, token});
    
    localStorage.setItem('token', data.token);
    window.location.href = '/admin';

    dispatch(verifyMFASuccess({ token: data.token, user: data.user }));
  } catch (error) {
    cogoToast.error(' Wrong code. ');
    console.log(`Login fail with error: ${error}`);
    dispatch(verifyMFAFail({ error }));
  }
};