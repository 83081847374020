import React, {useState} from 'react';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Col,
    DropdownItem,
  } from "reactstrap";
import { Alert } from "reactstrap";
import Avatar from 'react-avatar';
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';

import DeleteModal from '../../DeleteModal';
import Header from "../../Headers/SlimHeader";

const Message = (props) =>{
    const { data, deleteItem, total, getAll, authUser } = props;
    const [deleteItemId, setDeleteItemId] = useState();
    const limit = 10;
    const pageCount = Math.ceil(total / limit);

    const confirmDelete = ({event, id}) => {
      event.preventDefault();
      setDeleteItemId(id)
    }

    const del = () => {
        deleteItem({id: deleteItemId});
        setDeleteItemId(null);
    }

    const paginationChange = (data) => {
      getAll({limit, offset: data.selected})
    }

    
    return (
        <>
             <Header />
             <Container fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col sm={6}>
                      <h3 className="mb-0">All messages</h3> 
                    </Col>
                  </Row>
                  
                </CardHeader>
                <Table className="align-items-center table-flush" striped responsive>
                  <thead className="thead-light">
                    <tr>
                      <th style={{width:50}}>&nbsp;</th>
                      <th scope="col">Full name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Account</th>
                      <th scope="col">Country</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data && data.map(({id, fullName, email, mobile, country, Account, isRead}) => (
                        <tr key={id} style={{backgroundColor: isRead ? '#ffffff' : '#f2f2f2'}}>
                          <td><Avatar name={`${fullName}`} size="40" round={true} /></td>
                          <th>
                            {fullName}
                          </th>
                          <td>
                            {email}
                          </td>
                          <td>
                            {mobile}
                          </td>
                          <td>{Account ? Account.name : '-'}</td>
                          <td>
                            {country}
                          </td>
                          <td className="text-right" >
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    role="button"
                                    href="#"
                                    size="sm"
                                    color=""
                                    onClick={e => e.preventDefault()}
                                  >
                                    <i className="fa fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    <Link 
                                      className="dropdown-item"
                                      to={`/admin/messages/${id}/details`}
                                    >
                                      Details
                                    </Link>
                                    {
                                      authUser.role === 'superadmin' && (
                                        <DropdownItem 
                                          onClick={(event) => confirmDelete({event, id})}
                                        >
                                          Delete
                                        </DropdownItem>
                                      )
                                    }
                                    
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                </Table>
                <CardFooter className="py-4">

                {
                  total > limit && (
                    <ReactPaginate
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={10}
                      pageRangeDisplayed={5}
                      onPageChange={paginationChange}
                      containerClassName={'pagination justify-content-end mb-0'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      activeClassName={'active'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      previousLabel="«"
                      nextLabel="»"
                    />
                  )
                }
                  
                {
                  (!data || !data.length) && (
                      <Alert color="info">
                        <strong>No data</strong> You don't have any data yet!
                      </Alert>
                  )
                }

                {
                  deleteItemId && (<DeleteModal showModal={deleteItemId} toggleModal={() => setDeleteItemId()} confirmAction={del} title="Delete this message?" details="Are you sure you want to delete this message?" />)
                  
                }
                    
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        </>
    );
}

export default Message;