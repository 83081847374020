import React from 'react';
import { Card, Row, CardHeader,  Col, Button, CardBody } from 'reactstrap';
import moment from 'moment';
import Avatar from 'react-avatar';

import ApplicationCommentForm from './ApplicationCommentForm';

const ApplicationComment = ({history, comments, submit, submittingForm, applicationId, formData}) => {
    return (
        <Row>
            <Col className="order-xl-1" xl="8">
                <Card className="bg-white shadow">
                <CardHeader className="bg-white border-0">
                    
                    <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">Application Comments</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                            <Button
                            color="grey"
                            onClick={history.goBack}
                            size="sm"
                            >
                            Back
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                
                <CardBody> 
                    <ApplicationCommentForm formData={formData} submit={submit} submittingForm={submittingForm} applicationId={applicationId} />                   
                    <Row>
                        <Col xs="12">
                            <h4>Comments</h4>
                            {
                                comments.map(activity => (
                                    <Card className="p-4 mb-3 flex" key={activity.id}>
                                        <Row>
                                            
                                            {
                                            activity.User && (
                                                <Col xs="1">
                                                    <Avatar name={`${activity.User.firstName} ${activity.User.lastName}`} size="50" round={true} />
                                                </Col>
                                            )
                                        }
                                            
                                            <Col xs="10">
                                            
                                        <p className="date-text"><strong>{moment(activity.createdAt).fromNow()}</strong> at {moment(activity.createdAt).format('llll')} <br />
                                            <strong style={{fontWeight: 'bold'}}>By: {activity.User.firstName} {activity.User.lastName}</strong>
                                        </p>
                                        {activity.comment}
                                            </Col>
                                        </Row>
                                    </Card>
                                ))
                            }
                        </Col>
                    </Row>
                </CardBody>


            </Card>
        </Col>
        </Row>
    );
}

export default ApplicationComment;