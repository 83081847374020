import React from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';

const DependentInfo = (props) => {
    return (
        <Table className="align-items-center table-flush" responsive>
        <tbody>
        <tr>
            <th width="200">Title</th>
            <td>{props.title}</td>
        </tr>
        <tr>
            <th>First name</th>
            <td>{props.firstName}</td>
        </tr>
        <tr>
            <th>Last name</th>
            <td>{props.lastName}</td>
        </tr>
        <tr>
            <th>Gender</th>
            <td>{props.gender}</td>
        </tr>
        <tr>
            <th>Date of birth</th>
            <td>{moment(props.dateOfBirth).format('YYYY-MM-DD')}</td>
        </tr>
        </tbody>
        </Table>
    );
}

export default DependentInfo;