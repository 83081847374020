import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from './actions'
import * as accountActions from '../../Views/Account/actions';
import * as healthCoverActions from '../../Views/HealthCover/actions';
import ExportApplication from './ExportApplication';

class ExportApplicationContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: null
    }
  }

  componentDidMount(){
    const { actions: { getAccounts, getAllHealthCovers } } = this.props;
    getAccounts({ limit: 1000, offset: 0 });
    getAllHealthCovers({ limit: 1000, offset: 0, filter: {healthCoverType: 'oshc'} })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
      this.setState({showModal: false});
    }
  }

  render() {
    const { submittingForm, healthCovers,  authUser, accounts, type, actions: {submit} } = this.props;
    const { showModal } = this.state;

    return (
      <ExportApplication
        {...this.props}
        submit={submit}
        submittingForm={submittingForm}
        showModal={showModal}
        type={type}
        accounts={accounts}
        authUser={authUser}
        healthCovers={healthCovers}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    ...state.Admin.ExportApplication,
    accounts: state.Admin.Account.data,
    healthCovers: state.Admin.HealthCover.data
  }
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators({...actions, getAccounts: accountActions.getAll, getAllHealthCovers: healthCoverActions.getAllHealthCovers}, dispatch)
    }
  }
  

const ConnectedExportApplication = connect(mapStateToProps, mapDispatchToProps)(ExportApplicationContainer);

export { ConnectedExportApplication as ExportApplication };

