import * as c from './constants';

const initialState = {
  loadingHealthCovers: false,
  error: null,
  data: [],
  currentHealthCover: { name: '', agentUrl: '', api: '', isMonthlyOnly: false, monthlyPrice: 0, healthCoverTypeId: '', providerId: '', premium: [["", "", "", ""]]},
  submittingForm: false,
  deletingHealthCover: false
};


const actionHandlers = {
  [c.GET_HEALTH_COVERS_START]: (state) => {
    return {
      ...state,
      loadingHealthCovers: true,
    }
  },
  [c.GET_HEALTH_COVERS_SUCCESS]: (state, action) => {
    
    return {
      ...state,
      loadingHealthCovers: false,
      data: action.healthCovers.map(item => ({...item, premium: JSON.parse(item.premium)})),
    }
  },
  [c.GET_HEALTH_COVERS_FAIL]: (state, action) => {
    return {
      ...state,
      loadingHealthCovers: false,
      error: action.error
    }
  },

  
  [c.GET_CURRENT_HEALTH_COVER_START]: (state) => {
    return {
      ...state,
      loadingCurrent: true,
      error: null
    }
  },
  [c.GET_CURRENT_HEALTH_COVER_SUCCESS]: (state, action) => {
    return {
      ...state,
      loadingCurrent: false,
      currentHealthCover: action.currentItem,
      error: null
    }
  },
  [c.GET_CURRENT_HEALTH_COVER_FAIL]: (state, action) => {
    return {
      ...state,
      loadingCurrent: false,
      error: action.error
    }
  },
  [c.CLEAR_CURRENT_HEALTH_COVER]: (state) => {
    return {
      ...state,
      currentHealthCover: initialState.currentHealthCover
    }
  },

  [c.SUBMIT_HEALTH_COVER_START]: (state) => {
    return {
      ...state,
      submittingForm: true,
    }
  },
  [c.SUBMIT_HEALTH_COVER_SUCCESS]: (state, action) => {
    
    const alreadyExists = state.data.find(item => item.id === action.healthCover.id);
    let udpatedData;
    if(!alreadyExists) {
      udpatedData = [action.healthCover, ...state.data];
    }
    else {
      udpatedData = state.data.map(item => {
        if(item.id === action.healthCover.id) {
          return action.healthCover;
        }
        else {
          return item;
        }
      });
    }

    return {
      ...state,
      submittingForm: false,
      data: udpatedData,
      currentHealthCover: initialState.currentHealthCover
    }
  },
  [c.SUBMIT_HEALTH_COVER_FAIL]: (state, action) => {
    return {
      ...state,
      submittingForm: false,
      error: action.error
    }
  },

  [c.DELETE_HEALTH_COVER_START]: (state) => {
    return {
      ...state,
      deletingHealthCover: true
    }
  },
  [c.DELETE_HEALTH_COVER_SUCCESS]: (state, action) => {
    return {
      ...state,
      deletingHealthCover: false,
      data: state.data.filter(item => item.id !== action.id)
    }
  },
  [c.DELETE_HEALTH_COVER_FAIL]: (state) => {
    return {
      ...state,
      deletingHealthCover: false
    }
  },
}

export default (state = initialState, action) => {
  if (actionHandlers[action.type]) {
    return actionHandlers[action.type](state, action);
  }
  return state;
}
