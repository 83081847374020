import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import * as actions from './actions'
import Application from './Application';
import { Loader } from '../../Common'

class ApplicationContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { match: { params : { applicationId }} } = this.props;
    if(applicationId) {
      const { actions: { getApplication} } = this.props;
      getApplication({id: applicationId});
    }
    else {
      const {application} = this.props;
      if(!application.HealthCover.id) {
        window.location.href = `/cover-type/${this.props.match.params.coverType}/`;
      }
    }
  }



  render() {
    const { application, loadingCurrent, actions: {updateApplication} } = this.props;
    if(loadingCurrent) return (<Loader />);
    
    if(application.status && (application.status !== 'inProgress')) return(<Redirect to={'/'} />)
    
    return (
      <Application
        {...this.props}
        application={application}
        updateApplication={updateApplication}
      />
    )
  }
}

const mapStateToProps = state => {
  return state.Front.Application
}

const mapDispatchToProps = dispatch => {
    return {
      actions: bindActionCreators(actions, dispatch)
    }
  }
  

const ConnectedApplication = connect(mapStateToProps, mapDispatchToProps)(ApplicationContainer);

export { ConnectedApplication as Application };

