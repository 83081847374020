import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from '../../AuthUser/actions';


import MyProfileForm from './MyProfileForm';

class MyProfileFormContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {}
      }
      
      componentDidMount() {
        const { actions: { getAuthUser } } = this.props;
        getAuthUser();
      }

      componentDidUpdate(prevProps) {
        if(prevProps.submittingForm && !this.props.submittingForm && !this.props.error) {
          const {history}  = this.props;
          history.goBack();
        }
      }

      
      render() {
        const { currentItem, accounts, actions: { submit }} = this.props;
        
        return (
          <MyProfileForm
            {...this.props}
            currentItem={currentItem}
            submit={submit}
            accounts={accounts}
          />
        )
      }
}

const mapStateToProps = state => {
    return state.Admin.AuthUser
  }
  
  const mapDispatchToProps = dispatch => {
      return {
        actions: bindActionCreators(actions, dispatch)
      }
    }
    
  
  const ConnectedMyProfileForm = connect(mapStateToProps, mapDispatchToProps)(MyProfileFormContainer);
  
  export { ConnectedMyProfileForm as MyProfileForm };