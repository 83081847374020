import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
  } from "reactstrap";
import { withRouter } from 'react-router-dom';
import useForm from 'react-hook-form';
import * as yup from 'yup';

import Header from "../../../Headers/SlimHeader";

const formSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().required('Email is required').email('Invalid email address'),
  role: yup.string().required('Role is required'),
})


const UserForm = (props) => {
    const { currentItem, accounts, submittingForm, history, loggedInUser } = props;
   
    const { register, handleSubmit, errors, reset, setValue } = useForm({
      validationSchema: formSchema,
      defaultValues: currentItem,
    });
   
    const [formData, setFormData] = useState(currentItem);


    useEffect(() => {
      reset(currentItem);
      setFormData(currentItem);
    }, [currentItem, reset, setFormData]);

    const onSubmit = async (updatedItem) => {
      const { submit } = props;
      submit({data: {...currentItem, ...updatedItem}});
    }

    const handleInputChange = (event) => {
      const { target } = event;
      const name = target.name;
      const value = target.value;
      setFormData({...formData, [name]:  value});
      setValue([name], value);
    }

    const selectInput = (selected, name) => {
      setFormData({...formData, [name]: selected.id});
      setValue([name], selected.id, true);
    }

    useEffect(() => {
      register({name: 'accountId'});
  }, [register]);
    
    return (
        <>
            <Header />
            <Container fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-white shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                        <h3 className="mb-0">{formData.id ? 'Edit user' : 'Add new user'}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="grey"
                        onClick={history.goBack}
                        size="sm"
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="pl-lg-4">
                      
                      <Row>
                        <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >
                                First name
                              </label>
                              
                              <Input
                                  className="form-control-alternative"
                                  type="text"
                                  name="firstName"
                                  innerRef={register}
                                  onChange={handleInputChange}
                                  />
                                  {errors.firstName && (<span className="form-error">{errors.lastName.message}</span>)}
                            </FormGroup>

                          </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >
                                Last name
                              </label>
                              
                              <Input
                                  className="form-control-alternative"
                                  type="text"
                                  name="lastName"
                                  innerRef={register}
                                  onChange={handleInputChange}
                                  />
                                {errors.lastName && (<span className="form-error">{errors.lastName.message}</span>)}
                            </FormGroup>
                          </Col>
                      </Row>

                      <Row>
                        <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                              >
                                Email
                              </label>
                              
                              <Input
                                  className="form-control-alternative"
                                  type="text"
                                  name="email"
                                  innerRef={register}
                                  onChange={handleInputChange}
                                  />
                                  {errors.email && (<span className="form-error">{errors.email.message}</span>)}
                            </FormGroup>
                          </Col>
                      </Row>

                      {
                        !formData.id && (
                          <Row>
                            <Col lg="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                  >
                                    Password
                                  </label>
                                  
                                  <Input
                                      className="form-control-alternative"
                                      type="password"
                                      name="password"
                                      innerRef={register({required: true})}
                                      onChange={handleInputChange}
                                      />
                                </FormGroup>
                              </Col>
                          </Row>
                        )
                      }

                      
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                  >
                                    Role 
                                  </label>

                                  {
                                    loggedInUser.role === 'superadmin' && (
                                      <div className="custom-control custom-radio mb-3">
                                        <input
                                          className="custom-control-input"
                                          name="role"
                                          type="radio"
                                          value="superadmin"
                                          onChange={handleInputChange}
                                          defaultChecked={formData.role === 'superadmin'}
                                          ref={register({required: true})}
                                          id="superadminRole"
                                        />
                                        <label className="custom-control-label" htmlFor="superadminRole">
                                          Super admin
                                        </label>
                                      </div>
                                    )
                                  }

                                  <div className="custom-control custom-radio mb-3">
                                    <input
                                      className="custom-control-input"
                                      name="role"
                                      type="radio"
                                      onChange={handleInputChange}
                                      value="staff"
                                      ref={register({required: true})}
                                      defaultChecked={formData.role === 'staff'}
                                      id="staffRole"
                                    />
                                    <label className="custom-control-label" htmlFor="staffRole">
                                      Staff
                                    </label>
                                  </div>

                                  <div className="custom-control custom-radio mb-3">
                                    <input
                                      className="custom-control-input"
                                      name="role"
                                      type="radio"
                                      value="agent"
                                      onChange={handleInputChange}
                                      defaultChecked={formData.role === 'agent'}
                                      ref={register({required: true})}
                                      id="agentRole"
                                    />
                                    <label className="custom-control-label" htmlFor="agentRole">
                                      Agent / Account
                                    </label>
                                  </div>
                                  

                                </FormGroup>
                              </Col>
                          </Row>

                      { 
                         (formData.role === 'agent') &&  (
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                >
                                  Account
                                </label>

                                <Select
                                    value={accounts && accounts.find(item => item.id === formData.accountId)}
                                    getOptionLabel={option =>`${option.name} (${option.address})`}
                                    getOptionValue={option =>`${option.id}`}
                                    name="accountId"
                                    options={accounts}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={event => selectInput(event, 'accountId')}
                                />
                                
                              </FormGroup>
                            </Col>
                          </Row>
                        )
                      }
                      
   
                      <Row>
                          <Col lg="12">
                          <hr className="my-4" />
                            <div className="text-right">
                                <Button
                                    color="grey"
                                    type="button"
                                    onClick={history.goBack}
                                    >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={submittingForm? true : false}
                                    >
                                    {formData.id ? 'UPDATE' : "ADD"}
                                </Button>
                               
                            </div>
                          </Col>
                      </Row>
                    </div>
                   
                    </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        </>
    );
}

export default withRouter(UserForm);