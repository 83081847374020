import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Card, Button, InputGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import moment from 'moment';
import useForm from 'react-hook-form';
import {CardNumberElement, CardExpiryElement, CardCvcElement, injectStripe} from 'react-stripe-elements';
import * as yup from 'yup';
import LoadingOverlay from 'react-loading-overlay';

import { CoverDetails } from '../../common';


const formSchema = {
    name: yup.string().required('Name is required'),
    address_zip: yup.string().required('Post code is required')
};

const PaymentForm = ( props ) => {
    const { application, submitting, downloadInvoice, coverType, gettingPaymentStatus } = props;
    const [error, setError] = useState(null);
    const { register, handleSubmit,  errors } = useForm({
        validationSchema: yup.object().shape(formSchema),
        defaultValues: {name: '', address_zip: ''}
    });

    const  onSubmit = (data) => {
        const { makePayment } = props;
        
        if (props.stripe) {
            props.stripe
            .createToken(data)
            .then((payload) => {
                if(!payload.error) {
                    makePayment({applicationId: application.id, type: 'stripe', paymentToken: payload.token.id});
                }
                else {
                    setError(payload.error);
                }
            })
        } else {
            console.log("Stripe.js hasn't loaded yet.");
        }
    };

    const payWithPoli = () =>{
        const { makePayment } = props;
        makePayment({applicationId: application.id, type: 'poli', paymentToken: ''});
    }



    const downloadInvoiceAction = (event) => {
        event.preventDefault();
        downloadInvoice({id: application.id});
    }

    useEffect(() => {
        register({name: 'name'});
        register({name: 'address_zip'});
    }, [register]);

    return (
        <LoadingOverlay
            active={gettingPaymentStatus}
            spinner
            >
        <section className="section">
             <Container>
                   <Row>
                        <Col lg="8">
                            <Row>
                                <Col lg="6">
                                    <h2>Payment information</h2>
                                </Col>
                                <Col lg="6" className="text-right">
                                   <span className="form-info"> <span className="form-error">*</span> Indicates required field</span>
                                </Col>
                            </Row>
                            
                            <Card className="p-sm mb-sm">
                            <Form onSubmit={handleSubmit(onSubmit)} className="payment-form">
                            <Row>
                                <Col md="6">
                                    <h4>Pay with credit card</h4>
                                </Col>
                                <Col md="6">
                                    <div className="powered-by">
                                        <a href="https://stripe.com" rel="noopener noreferrer" target="_blank">
                                            <img alt="Powered by stripe" src="https://insurance-compare.s3-ap-southeast-2.amazonaws.com/powered_by_stripe.png" />
                                        </a> 
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <FormGroup className="payment-form-item">
                                        <Label>Name on card<span className="form-error">*</span></Label>
                                        <InputGroup className="input-group-alternative">
                                            <Input
                                                className="form-control-alternative"
                                                name="name"
                                                type="text"
                                                innerRef={register}
                                                />
                                            
                                        </InputGroup>
                                        {errors.name && (<span className="form-error">{errors.name.message}</span>)}
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup className="payment-form-item">
                                            <Label>Post code<span className="form-error">*</span></Label>
                                            <InputGroup className="input-group-alternative">
                                                <Input
                                                    className="form-control-alternative"
                                                    name="address_zip"
                                                    type="text"
                                                    innerRef={register}
                                                    />
                                            </InputGroup>
                                            {errors.address_zip && (<span className="form-error">{errors.address_zip.message}</span>)}
                                        </FormGroup>
                                       
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                <FormGroup className="payment-form-item">
                                <Label> Card number<span className="form-error">*</span> </Label>
                                    <CardNumberElement/>
                                    {
                                        error && error.code === "incomplete_number" && (<span className="form-error">{error.message}</span>)
                                    }
                                    {
                                        error && error.code === "invalid_number" && (<span className="form-error">{error.message}</span>)
                                    }
                                    {
                                        error && error.code === "card_declined" && (<span className="form-error">{error.message}</span>)
                                    }
                                </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg="6">
                                    <FormGroup className="payment-form-item">
                                        <Label> Expiration date<span className="form-error">*</span></Label>
                                        <CardExpiryElement/>
                                        {
                                            error && error.code === "incomplete_expiry" && (<span className="form-error">{error.message}</span>)
                                        }
                                        {
                                            error && error.code === "invalid_expiry_year_past" && (<span className="form-error">{error.message}</span>)
                                        }
                                    </FormGroup>
                                </Col>
                                
                                <Col lg="6">
                                    <FormGroup className="payment-form-item">
                                    <Label> CVC<span className="form-error">*</span></Label>
                                    <CardCvcElement/>
                                    {
                                        error && error.code === "incomplete_cvc" && (<span className="form-error">{error.message}</span>)
                                    }
                                    </FormGroup>
                                </Col>
                            </Row>
                            
                            <button disabled={submitting}>
                                <span>MAKE PAYMENT - <span>A${application.premium}</span></span>
                            </button>
                        </Form>

                        <div className="we-accept">
                            <h5>We accept</h5>
                            <img alt="Cards" src="https://insurance-compare.s3-ap-southeast-2.amazonaws.com/cards.png" />
                        </div>
                        </Card>

                        <Card className="p-sm mb-sm">
                                <Row>
                                    <Col lg="6"><h4>Pay with POLi</h4></Col>
                                    <Col lg="6">
                                        <div className="powered-by">
                                            <a href="http://www.polipayments.com/consumer">
                                                <img src="https://resources.apac.paywithpoli.com/poli-logo-43.png" height="50" alt="POLi Logo"/>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <p style={{fontWeight: 'bold'}}>Pay with your bank account using Internet Banking</p>
                                        <p>You will be redirected to your bank website to complete the payment.</p>
                                        <p>
                                            <a href="https://www.polipayments.com/buy" rel="noopener noreferrer" target="_blank">&raquo; Learn more about POLi</a> <br />
                                            <a href="https://transaction.apac.paywithpoli.com/POLiFISupported.aspx?merchantcode=S6104738" rel="noopener noreferrer" target="_blank">&raquo; Available Banks</a> <br />
                                        </p>

                                        <p>
                                            <Button
                                                onClick={payWithPoli}
                                                color="primary"
                                                size="lg"
                                                type="button"
                                                > PAY WITH POLI - <span>A${application.premium}</span></Button>
                                        </p>
                                    </Col>
                                </Row>
                        </Card>

                        <Card className="p-sm mb-sm">
                            <Row>
                                <Col md="12">
                                        <h4>Pay by Bank Transfer with in Australia</h4>
                                        <p>Transfer payment to the provided bank account and email your proof of payment at <a href="mailto:support@oshccompare.com.au">support@oshccompare.com.au</a>.  We will email your health certificate as soon as the payment is confirmed.</p>

                                        <Button
                                            color="secondary"
                                            size="lg"
                                            type="button"
                                            onClick={(event) => downloadInvoiceAction(event)}
                                            
                                            >
                                                DOWNLOAD INVOICE
                                        </Button>
                                    </Col>
                            </Row>
                        </Card>

                        </Col>
                        <Col lg="4">
                        <CoverDetails application={application} coverType={coverType} />
                        <div className="application-sidebar">
                                <div className="sidebar-action">
                                    <Link to={`/cover-type/oshc/application/${application.id}`}>
                                      <i className="fa fa-edit"></i>
                                    </Link>
                                    
                                </div>
                                <div>
                                    <h5>Personal information</h5>
                                    <p>
                                        Title: <span>{application.title}</span> <br />
                                        First name: <span>{application.firstName}</span> <br />
                                        Last name: <span>{application.lastName}</span> <br />
                                        Gender: <span>{application.gender}</span> <br />
                                        Date of birth: <span>{moment(application.dateOfBirth).format('YYYY-MM-DD')}</span> <br />
                                        Email address: <span>{application.email}</span> <br />
                                        Mobile: <span>{application.mobile}</span> <br />
                                        Country: <span>{application.country}</span> <br />
                                        Passport number: <span>{application.passportNumber}</span> <br />
                                        Visa type: <span>{application.visaType}</span> <br />
                                        School, college or university name: <span>{application.college || '-'}</span> <br />
                                        Student number: <span>{application.studentNumber || '-'}</span> <br />
                                        Australian address: <span>{application.australianAddress || '-'}</span> <br />
                                    </p>
                                    {
                                        (application.clientType === 'Couple' || application.clientType === 'Family') && (
                                            <>
                                                <h5>Partner Information</h5>
                                                <p>
                                                    Title: <span>{application.ApplicationPartner.title}</span> <br />
                                                    First name: <span>{application.ApplicationPartner.firstName}</span> <br />
                                                    Last name: <span>{application.ApplicationPartner.lastName}</span> <br />
                                                    Gender: <span>{application.ApplicationPartner.gender}</span> <br />
                                                    Date of birth: <span>{moment(application.ApplicationPartner.dateOfBirth).format('YYYY-MM-DD')}</span> <br />
                                                </p>
                                            </>
                                        )
                                    }
                                </div>
                            </div>

                            

                        </Col>
                   </Row>
              </Container>
        </section>
        </LoadingOverlay>
    );
}

export default injectStripe(PaymentForm);