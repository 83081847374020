import React, {useState} from 'react';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Col,
    DropdownItem
  } from "reactstrap";
  import { Alert } from "reactstrap";
  import Avatar from 'react-avatar';
import moment from 'moment';
import ReactPaginate from 'react-paginate';

import DeleteModal from '../../DeleteModal';
import Header from "../../Headers/SlimHeader";
import { ExportLead } from '../../Components';

const Lead = (props) =>{
    const { total, data, deleteItem, authUser, getAll } = props;
    const [deleteItemId, setDeleteItemId] = useState();
    const limit = 10;
    const pageCount = Math.ceil(total / limit);

    const confirmDelete = ({event, id}) => {
      event.preventDefault();
      setDeleteItemId(id)
    }

    const del = () => {
        deleteItem({id: deleteItemId});
        setDeleteItemId(null);
    }

    const paginationChange = (data) => {
      getAll({limit, offset: data.selected})
    }

    
    return (
        <>
             <Header />
             <Container fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col sm={6}>
                      <h3 className="mb-0">All OVHC Leads</h3> 
                    </Col>
                    {
                        (authUser.role === 'agent' || authUser.role === 'superadmin') && (<ExportLead authUser={authUser} />)
                    }
                  </Row>
                  
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">&nbsp;</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Start date</th>
                      <th scope="col">Client type</th>
                      <th scope="col">Children</th>
                      <th scope="col">HealthCover</th>
                      <th scope="col">Account</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data && data.map((lead) => (
                        <tr key={lead.id}>
                          <td>
                            <Avatar name={`${lead.firstName} ${lead.lastName}`} size="40" round={true} />
                          </td>
                          <th>
                             {lead.firstName} {lead.lastName}
                          </th>
                          <td>{lead.email}</td>
                          <td>{lead.mobile}</td>
                          <td>
                            {moment(lead.startDate).format('YYYY-MM-DD')}
                          </td>
                          <td>
                            {lead.clientType}
                          </td>
                          <td>
                            {lead.children}
                          </td>
                          <td>
                            {lead.HealthCover.name}
                          </td>
                          <td>
                            {lead.Account ? lead.Account.name : '-'}
                          </td>
                         
                          <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    role="button"
                                    href="#"
                                    size="sm"
                                    color=""
                                    onClick={e => e.preventDefault()}
                                  >
                                    <i className="fa fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                    <DropdownItem 
                                      onClick={(event) => confirmDelete({event, id: lead.id})}
                                    >
                                      Delete
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                </Table>
                <CardFooter className="py-4">
                
                {
                  total > limit && (
                    <ReactPaginate
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={10}
                      pageRangeDisplayed={5}
                      onPageChange={paginationChange}
                      containerClassName={'pagination justify-content-end mb-0'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      activeClassName={'active'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      previousLabel="«"
                      nextLabel="»"
                    />
                  )
                }
                
                  
                {
                  (!data || !data.length) && (
                      <Alert color="info">
                        <strong>No data</strong> You don't have any data yet!
                      </Alert>
                  )
                }

                {
                  deleteItemId && (<DeleteModal showModal={deleteItemId} toggleModal={() => setDeleteItemId()} confirmAction={del} title="Delete this lead?" details="Are you sure you want to delete this lead?" />)
                  
                }
                    
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        </>
    );
}

export default Lead;